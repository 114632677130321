import { Box, Drawer, List, Toolbar } from "@mui/material";
import React from "react";
import NavLogo from "../../../assets/images/nav-logo.jpg";
import SideNav from "./SideNav";
const drawerWidth = 240;

interface dashboardSideNavProps {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileOpen: boolean;
}

function DashboardSideNav({
  setMobileOpen,
  mobileOpen,
}: dashboardSideNavProps) {
  const container =
    window !== undefined ? () => window.document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: { md: drawerWidth },
        flexShrink: { md: 0 },
      }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#222222",
          },
        }}
      >
        <SideNav />
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            backgroundColor: "#222222",
          },
        }}
        open
      >
        <SideNav />
      </Drawer>
    </Box>
  );
}

export default DashboardSideNav;
