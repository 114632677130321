import { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormLabel,
  Grid,
  Typography,
  Tooltip,
  FormHelperText,
} from "@mui/material";
import { sendEmailData } from "../../requests/authRequest";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CampaigngForm } from "./style";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { useDispatch } from "react-redux";
interface EmailInputProps {
  label: string;
  value: string[];
  onChange: (value: string[]) => void;
  isRequired: boolean;
}

const EmailInput = ({
  label,
  value,
  onChange,
  isRequired,
}: EmailInputProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.split(",").map((v) => v.trim());
    onChange(newValue);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <Tooltip
        title="Note: Please add comma(,) to separate multiple users."
        style={{ cursor: "pointer" }}
      >
        <FormLabel component="legend" style={{ marginBottom: "0px" }}>
          {label}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </FormLabel>
      </Tooltip>
      <input
        type="email"
        multiple
        name="user_email"
        placeholder="cashdillo@example.com"
        required={isRequired}
        style={{ width: "100%", height: "50px" }}
        value={value.join(", ")}
        onChange={handleOnChange}
      />
    </div>
  );
};

const SendEmail = () => {
  const [to, setTo] = useState<string[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [cc, setCC] = useState<string[]>([]);
  const [bcc, setBCC] = useState<string[]>([]);
  const [contentError, setContentError] = useState<boolean>(false);

  const dispatch = useDispatch();

  const handleOnSubmit = async (e: any) => {
    e.preventDefault();
    if (!text) {
      setContentError(true);
    } else {
      dispatch(toggleAppLoading(true));
      const payload = {
        to: to,
        subject: subject,
        text: text,
        cc: cc,
        bcc: bcc,
      };

      const res = await sendEmailData(payload);

      if (res.success) {
        const toasterData = {
          active: true,
          text: "Email sent successfully",
          success: true,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
      setTo([]);
      setSubject("");
      setText("");
      setCC([]);
      setBCC([]);
    }
  };

  const handleChangeEditor = (e: any, editor: any) => {
    const data = editor.getData();
    setText(data);
    setContentError(false);
  };

  return (
    <Box sx={CampaigngForm}>
      <form onSubmit={handleOnSubmit}>
        <Grid container spacing={2}>
          <Grid
            item
            lg={6}
            md={6}
            sx={{ width: { md: "auto", sm: "50%", xs: "100%" } }}
          >
            <Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="h4"
                  align="left"
                  mb={4}
                  fontWeight={600}
                  marginBottom={2}
                >
                  Send Email
                </Typography>
              </div>

              <EmailInput
                label="To"
                value={to}
                onChange={setTo}
                isRequired={true}
              />
              <EmailInput
                label="Cc"
                value={cc}
                onChange={setCC}
                isRequired={false}
              />
              <EmailInput
                label="Bcc"
                value={bcc}
                onChange={setBCC}
                isRequired={false}
              />

              <div style={{ marginTop: "20px" }}>
                <FormLabel component="legend" style={{ marginBottom: "0px" }}>
                  Subject<span style={{ color: "red" }}> *</span>
                </FormLabel>
                <input
                  type="text"
                  name="user_name"
                  placeholder="subject"
                  required
                  style={{ width: "100%", height: "50px" }}
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </div>

              <div style={{ marginTop: "20px" }}>
                <FormLabel component="legend" style={{ marginBottom: "0px" }}>
                  Content<span style={{ color: "red" }}> *</span>
                </FormLabel>
                <Box style={{ width: "100%", height: "300px" }}>
                  <CKEditor
                    editor={ClassicEditor}
                    required={true}
                    data={text}
                    onChange={handleChangeEditor}
                    style={{ width: "100%", height: "100%" }}
                    config={{
                      mediaEmbed: {
                        previewsInData: true,
                      },
                      toolbar: [
                        "undo",
                        "redo",
                        "bold",
                        "italic",
                        "numberedList",
                        "bulletedList",
                        "heading",
                        "fontfamily",
                        "fontsize",
                        "fontBackgroundColor",
                        "strikethrough",
                        "subscript",
                        "superscript",
                        "code",
                        "bulletedList",
                        "numberedList",
                        "todoList",
                        "outdent",
                        "indent",
                        "blockQuote",
                        "codeBlock",
                        "insertTable",
                        "link",
                        "mediaEmbed",
                      ],
                    }}
                  />
                </Box>
                {contentError && (
                  <FormHelperText className="css-l8pll-MuiFormHelperText-root Mui-error">
                    Please enter the content
                  </FormHelperText>
                )}
              </div>
              <Button
                variant="contained"
                color="primary"
                className="round-button"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Send
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SendEmail;
