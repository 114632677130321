export const DetailUser = {
  display: "flex",
  alignItems: "baseline",
  "& .label": {
    fontSize: "24px",
    maxWidth: "312px",
    textAlign: "left",
    width: "100%",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
      maxWidth: "240px",
    },
  },
  "& .value": {
    fontSize: "24px",
    textAlign: "left",
    width: "auto",
    padding: "0px 10px",
    "@media (max-width:1600px) ": {
      fontSize: "18px",
    },
  },
  "& .border-value": {
    border: "1px solid #c7c9c9",
  },
};
