import {
  Box,
  Typography,
  Stack,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextareaAutosize,
  TextField,
  FormHelperText,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveCampaign,
  getCampaignById,
} from "../../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { Link } from "react-router-dom";
import { setBlockUserCampaignModal } from "../../../store/slices/ModalSlice";
import { useWallet } from "@txnlab/use-wallet";
import { client } from "../../../algorand";
import { approveCampaignBlockchain } from "../../../blockchain";
import algosdk from "algosdk";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import algo from "../../../assets/images/algo.png";
import Wallets from "../../../algorandWallet";
import YouTube from "react-youtube";
import { renderToString } from "react-dom/server";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");

interface campaign {
  campaignId: string;
  youTubeLink: any;
  campaignAppId: string;
  category: {
    title: string;
    categoryId: string;
  };
  currency: string;
  description: string;
  endDate: string | Date;
  fundingCategory: {
    title: string;
    fundingCategoryId: string;
  };
  goalAmount: number;
  images: Array<string>;
  rewardBased: boolean;
  status: string;
  title: string;
  country: string;
  createdBy: {
    name: string;
    country: string;
    userId: string;
  };
  video: string;
  additionalInfo: boolean;
  milestones: Array<any>;
  goalIds: Array<any>;
  additionalInfoId: {
    consent: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    companyOrProjectName: string;
    website: string;
    projectPitch: string;
    problems: string;
    previousSuccessfulProjects: string;
    hq: string;
    geographies: string;
    impact: string;
    partnerships: string;
    yearOfEstablishment: string;
    companyBio: string;
    additionalInformation: string;
    image: string;
    reachoutRegardingSubmission: boolean;
  };
  isEsgEnabled: boolean;
  rating: string;
  matchFunding: boolean;
  reward: string;
  expectedResults: string;
  expectedYearlyoutput: number;
  expectedCostPerUnit: string;
  location: any;
  lat: any;
  lng: any;
  isReforestationProject: boolean;
}

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ViewCampaign() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [campaignInfo, setCampaignInfo] = useState<campaign>();
  const [rating, setRating] = useState<string>(campaignInfo?.rating || "");
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const blockModal = useAppSelector(
    (state) => state.modalReducer.blockUserCampaign
  );

  const getCampaignInfo = async () => {
    if (campaignId) {
      dispatch(toggleAppLoading(true));
      const res: any = await getCampaignById(campaignId);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        setCampaignInfo(res.data.campaign);
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
        navigate(-1);
      }
    } else {
      const toasterData = {
        active: true,
        text: "Trying to access info of invalid user.",
        success: false,
      };
      dispatch(setToaster(toasterData));
      navigate(-1);
    }
  };

  //pera
  const handleAcceptCampaignBlockchain = async () => {
    const info = {
      admin_wallet_address: activeAccount?.address,
      campaign_app_id: campaignInfo?.campaignAppId,
      note: 1,
      ESG: campaignInfo?.isEsgEnabled ? parseFloat(rating) : 0,
    };
    const unsignedTxn = await approveCampaignBlockchain(info);
    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: "Something went wrong, please try again later.",
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));
      //false loading

      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));
      //true loading

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      let transactionResponse = await client
        .pendingTransactionInformation(transationRes.txId)
        .do();
      dispatch(setAppLoadingText(""));

      return {
        success: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const [invalidRating, setInvalidRating] = useState<boolean>(false);
  const [errorRating, setErrorRating] = useState<string>("");

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleAcceptCampaign = async () => {
    let validity = true;
    if (campaignInfo?.isEsgEnabled && !rating.trim().length) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter the Rating");
      return;
    } else if (
      (campaignInfo?.isEsgEnabled && Number(rating.trim()) < 1) ||
      Number(rating.trim()) > 100
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter valid Rating");
      return;
    } else if (
      campaignInfo?.isEsgEnabled &&
      !Number.isInteger(Number(rating.trim()))
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter only non-decimal values");
      return;
    } else {
      setInvalidRating(false);
      setErrorRating("");
    }
    if (campaignId) {
      dispatch(toggleAppLoading(true));

      const blockchainRes = await handleAcceptCampaignBlockchain();
      if (!blockchainRes.success) {
        return;
      }
      const info = {
        campaignId: campaignId,
        ...(campaignInfo?.isEsgEnabled ? { rating: rating } : {}),
      };
      const res = await approveCampaign(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: "Campaign approved",
          success: true,
        };
        await getCampaignInfo();
        dispatch(setToaster(toasterData));
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    }
  };
  const handleRejectCampaign = async () => {
    let validity = true;
    if (campaignInfo?.isEsgEnabled && !rating.trim().length) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter the Rating");
      return;
    } else if (
      (campaignInfo?.isEsgEnabled && Number(rating.trim()) < 1) ||
      Number(rating.trim()) > 100
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter valid Rating");
      return;
    } else if (
      campaignInfo?.isEsgEnabled &&
      !Number.isInteger(Number(rating.trim()))
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter only non-decimal values");
      return;
    } else {
      setInvalidRating(false);
      setErrorRating("");
    }

    const payload = {
      blockUserCampaignType: "reject",
      blockUserCampaignId: campaignId ? campaignId : "",
      blockUserCampaign: true,
      admin_wallet_address: "",
      campaign_app_id: "",
      milesstone_app_id: [],
    };
    dispatch(setBlockUserCampaignModal(payload));
  };
  const handleBlockCampaign = async () => {
    let milestones = [];
    milestones.push(
      campaignInfo?.milestones[0].milestoneAppId,
      campaignInfo?.milestones[1].milestoneAppId
    );
    const payload = {
      blockUserCampaignType: "block",
      blockUserCampaignId: campaignId ? campaignId : "",
      blockUserCampaign: true,
      admin_wallet_address: activeAccount ? activeAccount.address : "",
      campaign_app_id: campaignInfo?.campaignAppId || "",
      milesstone_app_id: milestones || [],
    };
    dispatch(setBlockUserCampaignModal(payload));
  };

  useEffect(() => {
    getCampaignInfo();
  }, [campaignId]);

  useEffect(() => {
    if (!blockModal) {
      getCampaignInfo();
    }
  }, [blockModal]);

  return (
    <>
      {campaignInfo ? (
        <Box>
          <Typography variant="h4" align="left" mb={4} fontWeight={600}>
            View Campaign Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Title
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Description
                  </Typography>
                  <Typography
                    variant="h6"
                    align="justify"
                    style={{ whiteSpace: "pre-line", textAlign: "justify" }}
                  >
                    {campaignInfo.description}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Status
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.status.charAt(0).toUpperCase() +
                      campaignInfo.status.slice(1)}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    End Date
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(campaignInfo.endDate).format("MMM DD YYYY")}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Country
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.country.charAt(0).toUpperCase() +
                      campaignInfo.country.slice(1)}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Category
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.category.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Goal Amount
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.goalAmount}{" "}
                    <img
                      src={algo}
                      style={{ height: "15px", width: "15px" }}
                      alt=""
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Creator
                  </Typography>
                  <Link
                    to={`/dashboard/user/${campaignInfo.createdBy.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {campaignInfo.createdBy.name}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Funding Category
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.fundingCategory.title}
                  </Typography>
                  {campaignInfo?.fundingCategory?.title === "Reward" &&
                    campaignInfo?.reward && (
                      <Typography variant="h6" align="left">
                        Selected NFT - {campaignInfo?.reward}
                      </Typography>
                    )}
                </Box>
              </Stack>
            </Grid>
            <Grid item lg={8} md={6}>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  Image
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={10} lg={10}>
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={campaignInfo?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-campaign"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {campaignInfo?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "500px",
                            height: "500px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                        />
                      ))}
                    </Carousel>
                  </Grid>
                </Grid>
              </Box>
              {campaignInfo?.video && (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="h5" fontWeight="600" align="left" mb={1}>
                    Video (Optional)
                  </Typography>
                  <Typography variant="h6" align="left">
                    <video
                      style={
                        campaignInfo.video
                          ? { maxWidth: "98%", height: "auto" }
                          : { display: "none" }
                      }
                      src={`${URL}/api/file/video/${campaignInfo.video}`}
                      controls
                      width="50%"
                      height="auto"
                      autoPlay={false}
                    />
                  </Typography>
                </Box>
              )}
              {campaignInfo?.youTubeLink && (
                <Box
                  sx={{
                    border: "1px solid #cecbcb",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="h5" fontWeight="600" align="left" mb={1}>
                    Youtube Video (Optional)
                  </Typography>
                  <Typography variant="h6" align="left">
                    {campaignInfo.youTubeLink &&
                      (() => {
                        const id = getYouTubeID(campaignInfo.youTubeLink);
                        return (
                          <YouTube
                            videoId={id}
                            opts={{
                              height: "300",
                              width: "51%",
                              playerVars: {
                                autoplay: 1,
                              },
                            }}
                            style={
                              campaignInfo.youTubeLink
                                ? { maxWidth: "98%", height: "auto" }
                                : { display: "none" }
                            }
                          />
                        );
                      })()}
                  </Typography>
                </Box>
              )}
            </Grid>
            <Grid container spacing={2} style={{ paddingLeft: "15px" }}>
              <Grid item lg={12} md={12}>
                <Box
                  sx={{ mb: 2 }}
                  style={{ textAlign: "left", marginTop: "40px" }}
                >
                  <Typography variant="h4" align="left" mb={3} fontWeight={600}>
                    Milestones Detail
                  </Typography>
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        Milestone 1
                      </Typography>
                      <p style={{ marginBottom: "0" }}>Title</p>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        value={campaignInfo.milestones[0].title}
                        disabled
                        style={{ width: "100%" }}
                        sx={{ mt: 1, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Description</p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo.milestones[0].definition}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <p style={{ marginBottom: "0" }}>Deadlines</p>
                      <TextField
                        id="outlined-basic"
                        value={moment(
                          campaignInfo.milestones[0].deadline
                        ).format("DD-MMM-YYYY")}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 1, mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        Milestone 2
                      </Typography>
                      <p style={{ marginBottom: "0" }}>Title</p>
                      <TextField
                        id="outlined-basic"
                        value={campaignInfo.milestones[1].title}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 1, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Description</p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo.milestones[1].definition}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <p style={{ marginBottom: "0" }}>Deadlines</p>
                      <TextField
                        id="outlined-basic"
                        value={moment(
                          campaignInfo.milestones[1].deadline
                        ).format("DD-MMM-YYYY")}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 1, mb: 2 }}
                      />
                    </Grid>

                    <Typography
                      variant="h4"
                      align="left"
                      mb={3}
                      mt={3}
                      fontWeight={600}
                      ml={3}
                    >
                      Is this Campaign a Reforestation Campaign?
                    </Typography>

                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      {campaignInfo.isReforestationProject === true ? (
                        <>
                          <Grid item xs={6}>
                            <p
                              style={{ marginBottom: "0", marginLeft: "18px" }}
                            >
                              Project Output
                            </p>
                            <TextField
                              id="outlined-basic"
                              value={campaignInfo.expectedYearlyoutput || 0}
                              disabled
                              variant="outlined"
                              style={{ width: "100%" }}
                              sx={{ mt: 1, mb: 2, ml: 2 }}
                            />
                            <p
                              style={{ marginBottom: "0", marginLeft: "18px" }}
                            >
                              Expected Results
                            </p>
                            <TextField
                              id="outlined-basic"
                              value={campaignInfo.expectedResults || "test"}
                              disabled
                              multiline
                              variant="outlined"
                              style={{ width: "100%" }}
                              sx={{ mt: 1, mb: 2, ml: 2 }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <p
                              style={{ marginBottom: "0", marginLeft: "18px" }}
                            >
                              Cost Per Unit
                            </p>
                            <TextField
                              id="outlined-basic"
                              value={campaignInfo.expectedCostPerUnit || "test"}
                              disabled
                              variant="outlined"
                              style={{ width: "100%" }}
                              sx={{ mt: 1, mb: 2, ml: 2 }}
                            />

                            <p
                              style={{ marginBottom: "0", marginLeft: "18px" }}
                            >
                              Location
                            </p>
                            <TextField
                              id="outlined-basic"
                              value={
                                campaignInfo.location === null
                                  ? `Latitude: ${campaignInfo.lat} &  Longitude: ${campaignInfo.lng}`
                                  : campaignInfo.location
                              }
                              // value={campaignInfo.location === null ?
                              //   (<> <p> Latitude :{campaignInfo.lat}</p> <p> Longitude: {campaignInfo.lng}</p> </>)
                              //   : (<p> Location :{campaignInfo.location}</p>)}
                              disabled
                              variant="outlined"
                              style={{ width: "100%" }}
                              sx={{ mt: 1, mb: 2, ml: 2 }}
                            />
                          </Grid>
                        </>
                      ) : (
                        <FormControl sx={{ mt: 1, minWidth: 300, ml: 5 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            No
                          </InputLabel>
                          <Select disabled displayEmpty>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </Grid>

                    <Box
                      sx={{ mt: 2, mb: 3 }}
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                    >
                      <p style={{ marginBottom: "0" }}>
                        Is your campaign aligned with any Sustainable
                        Development Goal(SDG)?
                      </p>
                      <FormControl sx={{ mt: 1, minWidth: 300 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {campaignInfo?.isEsgEnabled ? "Yes" : "No"}
                        </InputLabel>
                        <Select disabled displayEmpty>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>
                </Box>
                {campaignInfo?.isEsgEnabled && (
                  <Box>
                    <Box
                      sx={{ mb: 3 }}
                      style={{ textAlign: "left", marginTop: "60px" }}
                    >
                      <Typography
                        variant="h4"
                        align="left"
                        mb={3}
                        fontWeight={600}
                      >
                        Campaign Goal
                      </Typography>
                      <Box
                        sx={{ mb: 3 }}
                        style={{ textAlign: "left", marginTop: "60px" }}
                      >
                        <p style={{ marginBottom: "0" }}>
                          Do yo want to receive Match Funding?
                        </p>
                        <FormControl sx={{ mt: 1, minWidth: 300 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {campaignInfo?.matchFunding ? "Yes" : "No"}
                          </InputLabel>
                          <Select disabled displayEmpty>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                      {campaignInfo.goalIds?.filter(
                        (el) => el?.category === "environmental"
                      ).length > 0 && (
                        <Typography variant="h5" align="left" mt={3} mb={3}>
                          Environmental
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        {campaignInfo.goalIds
                          ?.filter((el) => el?.category === "environmental")
                          ?.map((item: any) => (
                            <Grid xs={4} pl={2}>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked
                                      disabled
                                      name="Goal"
                                      color="primary"
                                    />
                                  }
                                  label={item?.goalName}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      {campaignInfo.goalIds?.filter(
                        (el) => el?.category === "social"
                      ).length > 0 && (
                        <Typography variant="h5" align="left" mt={3} mb={3}>
                          Social
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        {campaignInfo.goalIds
                          ?.filter((el) => el?.category === "social")
                          ?.map((item: any) => (
                            <Grid xs={4} pl={2}>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked
                                      disabled
                                      name="Goal"
                                      color="primary"
                                    />
                                  }
                                  label={item?.goalName}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      {campaignInfo.goalIds?.filter(
                        (el) => el?.category === "governance"
                      ).length > 0 && (
                        <Typography variant="h5" align="left" mt={3} mb={3}>
                          Governance
                        </Typography>
                      )}
                      <Grid container spacing={2}>
                        {campaignInfo.goalIds
                          ?.filter((el) => el?.category === "governance")
                          ?.map((item: any) => (
                            <Grid xs={4} pl={2}>
                              <Box>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked
                                      disabled
                                      name="Goal"
                                      color="primary"
                                    />
                                  }
                                  label={item?.goalName}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                      <Box
                        sx={{ mb: 3 }}
                        style={{ textAlign: "left", marginTop: "60px" }}
                      >
                        <p style={{ marginBottom: "0" }}>
                          Do you want to share more information about your
                          campaign with us?
                        </p>
                        <FormControl sx={{ mt: 1, minWidth: 300 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {campaignInfo?.additionalInfo ? "Yes" : "No"}
                          </InputLabel>
                          <Select disabled displayEmpty>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Grid>
            </Grid>

            {campaignInfo?.isEsgEnabled && campaignInfo?.additionalInfo && (
              <Box
                sx={{ mt: 2, mb: 3 }}
                style={{ textAlign: "left", paddingLeft: "15px" }}
              >
                <Typography variant="h4" align="left" mb={3} fontWeight={600}>
                  Additional Campaign Information
                </Typography>
                <Typography
                  style={{ textAlign: "left", fontSize: "1rem" }}
                  variant="h5"
                >
                  Privacy and data collection
                </Typography>
                <p>
                  We collect company specific information for the Cashdillo
                  analysis, but also personal information (such as reference,
                  names, email address, etc. for our SDG vetting ). Private
                  information will remain private, and will not be shared with
                  or disclosed to any organisation without your consent. Before
                  sharing your contact information with another party we will
                  reach out to you to receive your consent to do so. By filling
                  out this form, you accept that we will process your data in
                  line with our privacy policy.
                </p>
                <p style={{ marginBottom: "0" }}>Please give your consent</p>
                <FormControl sx={{ mt: 1, minWidth: 300 }}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {campaignInfo?.additionalInfoId?.consent ? "Yes" : "No"}
                  </InputLabel>
                  <Select disabled displayEmpty>
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            )}
            {campaignInfo?.isEsgEnabled &&
              campaignInfo?.additionalInfo &&
              campaignInfo?.additionalInfoId?.consent && (
                <Box
                  sx={{ mt: 2, mb: 3 }}
                  style={{ textAlign: "left", paddingLeft: "15px" }}
                >
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  >
                    <Grid item xs={4}>
                      <p style={{ marginBottom: "0" }}>Contact name</p>
                      <TextField
                        id="outlined-basic"
                        value={campaignInfo?.additionalInfoId?.contactName}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Contact email</p>
                      <TextField
                        id="outlined-basic"
                        value={campaignInfo?.additionalInfoId?.contactEmail}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Contact phone</p>
                      <TextField
                        id="outlined-basic"
                        value={campaignInfo?.additionalInfoId?.contactPhone}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>
                        Company / Project / Organization name
                      </p>
                      <TextField
                        id="outlined-basic"
                        value={
                          campaignInfo?.additionalInfoId?.companyOrProjectName
                        }
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Website</p>
                      <TextField
                        id="outlined-basic"
                        value={campaignInfo?.additionalInfoId?.website}
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Project pitch</p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo?.additionalInfoId?.projectPitch}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <p style={{ marginBottom: "0" }}>
                        Problems you are Solving
                      </p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo?.additionalInfoId?.problems}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ marginBottom: "0" }}>
                        Previous successful projects (Optional)
                      </p>
                      <TextField
                        id="outlined-basic"
                        value={
                          campaignInfo?.additionalInfoId
                            ?.previousSuccessfulProjects
                            ? campaignInfo?.additionalInfoId
                                ?.previousSuccessfulProjects
                            : ""
                        }
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>HQ (Optional)</p>
                      <TextField
                        id="outlined-basic"
                        value={
                          campaignInfo?.additionalInfoId?.hq
                            ? campaignInfo?.additionalInfoId?.hq
                            : ""
                        }
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>Country</p>
                      <TextField
                        id="outlined-basic"
                        value={
                          campaignInfo?.additionalInfoId?.geographies
                            ? campaignInfo?.additionalInfoId?.geographies
                                .charAt(0)
                                .toUpperCase() +
                              campaignInfo?.additionalInfoId?.geographies.slice(
                                1
                              )
                            : ""
                        }
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                      <p style={{ marginBottom: "0" }}>
                        What do you hope to achieve with this project?
                      </p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo?.additionalInfoId?.impact}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <p style={{ marginBottom: "0" }}>
                        Partnerships (Optional)
                      </p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={
                          campaignInfo?.additionalInfoId?.partnerships
                            ? campaignInfo?.additionalInfoId?.partnerships
                            : ""
                        }
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <p style={{ marginBottom: "0" }}>
                        Year of Establishment (Optional)
                      </p>
                      <TextField
                        id="outlined-basic"
                        value={
                          campaignInfo?.additionalInfoId?.yearOfEstablishment
                            ? moment(
                                campaignInfo?.additionalInfoId
                                  ?.yearOfEstablishment
                              ).format("YYYY-MM-DD")
                            : ""
                        }
                        disabled
                        variant="outlined"
                        style={{ width: "100%" }}
                        sx={{ mt: 2, mb: 2 }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <p style={{ marginBottom: "0" }}>Stage description</p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={campaignInfo?.additionalInfoId?.companyBio}
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      {campaignInfo?.additionalInfoId?.image && (
                        <Grid item xl={4}>
                          <p style={{ marginBottom: "0" }}>
                            Image&nbsp;(Optional)
                          </p>
                          <img
                            src={`${URL}/api/file/image/${campaignInfo?.additionalInfoId?.image}`}
                            alt="campaign"
                            width={500}
                            // style={{ maxWidth: "100%", width: "500px" }}
                          />
                        </Grid>
                      )}
                      <p style={{ marginBottom: "0" }}>
                        Additional information (Optional)
                      </p>
                      <TextareaAutosize
                        aria-label="minimum height"
                        minRows={7}
                        disabled
                        value={
                          campaignInfo?.additionalInfoId?.additionalInformation
                            ? campaignInfo?.additionalInfoId
                                ?.additionalInformation
                            : ""
                        }
                        style={{ borderRadius: "5px", width: "100%" }}
                      />
                      <FormHelperText>
                        This is a hint text to user
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Box>
              )}
            {campaignInfo?.isEsgEnabled && (
              <Grid container spacing={2} style={{ paddingLeft: "15px" }}>
                <Grid item lg={12} md={12}>
                  {campaignInfo?.additionalInfo &&
                    campaignInfo?.additionalInfoId?.consent && (
                      <Box sx={{ mt: 2, mb: 3 }} style={{ textAlign: "left" }}>
                        <p>
                          Is it Ok for a team member of Cashdillo to reach out
                          to you in regards to your submission?
                        </p>
                        <FormControl sx={{ mt: 0, minWidth: 300 }}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {campaignInfo?.additionalInfoId
                              ?.reachoutRegardingSubmission
                              ? "Yes"
                              : "No"}
                          </InputLabel>
                          <Select disabled>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  <Box sx={{ mt: 2, mb: 3 }} style={{ textAlign: "left" }}>
                    {campaignInfo?.status === "rejected" ||
                    campaignInfo?.status === "blocked" ? null : (
                      <Box>
                        <Typography variant="h4" align="left" fontWeight={600}>
                          Cashdillo Campaign Rating
                        </Typography>
                        <p>Enter the right rating for this campaign (1-100)</p>
                        <FormControl sx={{ mt: 0, minWidth: 300 }}>
                          <TextField
                            id="outlined-basic"
                            label="Rating"
                            variant="outlined"
                            sx={{ mt: 2, mb: 2, width: "100%" }}
                            disabled={campaignInfo.status !== "pending"}
                            value={rating || campaignInfo.rating}
                            onChange={(e) => {
                              setRating(e.target.value);
                              setInvalidRating(false);
                            }}
                            error={invalidRating}
                            helperText={invalidRating && errorRating}
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.buttongrey",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary.buttongreydark",
                    },
                    "&:focus": {
                      backgroundColor: "primary.buttongreydark",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
                {campaignInfo.status === "active" && (
                  <Button
                    variant="contained"
                    color={"error"}
                    sx={{ mt: 4 }}
                    onClick={handleBlockCampaign}
                  >
                    Block Campaign
                  </Button>
                )}
                {campaignInfo.status === "pending" && (
                  <>
                    <>
                      {new Date().setHours(0, 0, 0, 0) >
                      new Date(campaignInfo.endDate).setHours(0, 0, 0, 0) ? (
                        <Tooltip title="Campaign end date is expired now">
                          <Button
                            variant="contained"
                            color={"primary"}
                            sx={{
                              backgroundColor: "primary.buttongrey",
                              mt: 4,
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "primary.buttongreydark",
                              },
                              "&:focus": {
                                backgroundColor: "primary.buttongreydark",
                              },
                            }}
                          >
                            Approve Campaign
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          disabled={
                            new Date().setHours(0, 0, 0, 0) >
                            new Date(campaignInfo.endDate).setHours(0, 0, 0, 0)
                          }
                          variant="contained"
                          color={"primary"}
                          sx={{ mt: 4, mr: 2 }}
                          onClick={handleShowWalletOptions}
                        >
                          Approve Campaign
                        </Button>
                      )}

                      {showWalletButtons && (
                        <Wallets
                          open={showWalletButtons}
                          handleClose={() => setShowWalletButtons(false)}
                          handleSubmit={handleAcceptCampaign}
                        />
                      )}
                    </>
                    <Button
                      variant="contained"
                      color={"error"}
                      sx={{ mt: 4 }}
                      onClick={handleRejectCampaign}
                    >
                      Reject Campaign
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewCampaign;
