export const DataGridWrap = {
  height: "500px",
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "14px",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "14px",
      padding: "12px 12px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
      textAlign: "left",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};
export const SearchRow = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  mb: 2,
  "& .MuiOutlinedInput-root": {
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: { xl: "16px", xs: "14px" },
      fontFamily: "Poppins, sans-serif",
      padding: { xl: "12px 20px 12px 0px", xs: "12px 15px 15px 0px" },
      borderRadius: "6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
};
export const footer = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
};
