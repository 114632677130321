export const LoginWrapper = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  padding: "20px 0px",
  backgroundColor: "#f1f3f4",
  "& .logo": {
    height: "100px",
    width: "auto",
    mb: 2,
  },
};
export const LoginBox = {
  padding: "50px 24px",
  backgroundColor: "#ffffff",
  width: { sm: "550px", xs: "100%" },
  boxShadow: 2,
  borderRadius: 3,
  "& .MuiFormControl-root": {
    marginBottom: 3,
  },
  "& .MuiOutlinedInput-root": {
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: { xl: "16px", xs: "14px" },
      padding: { xs: "14px 15px" },
      borderRadius: "6px",
    },
  },
};
