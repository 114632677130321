import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";
import React, { useState } from "react";
import { client } from "../../algorand";
import { rejectCampaignBlockchain } from "../../blockchain";
import { blockCampaign, rejectCampaign } from "../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setBlockUserCampaignModal } from "../../store/slices/ModalSlice";
import {
  ContentStyle,
  modalContainer,
  modalWrapper,
  titleStyle,
  textArea,
} from "./styles";
import Wallets from "../../algorandWallet";

function BlockReasonModal() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const open = useAppSelector((state) => state.modalReducer.blockUserCampaign);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState<string>("");
  const [invalidReason, setInvalidReason] = useState<boolean>(false);
  const blockType = useAppSelector(
    (state) => state.modalReducer.blockUserCampaignType
  );
  const blockId = useAppSelector(
    (state) => state.modalReducer.blockUserCampaignId
  );
  const blockChainData = useAppSelector((state) => state.modalReducer);

  const handleClose = () => {
    setReason("");
    setInvalidReason(false);
    const payload = {
      blockUserCampaignType: "",
      blockUserCampaignId: "",
      blockUserCampaign: false,
      admin_wallet_address: "",
      campaign_app_id: "",
      milesstone_app_id: [],
    };
    dispatch(setBlockUserCampaignModal(payload));
  };

  const validateReason = () => {
    let res = true;
    if (reason.trim().length <= 0 || reason.trim().length > 3000) {
      res = false;
      setInvalidReason(true);
    } else {
      setInvalidReason(false);
    }
    return res;
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleRejectCampaign = async () => {
    const valid = validateReason();

    if (!valid) {
      return;
    }
    if (blockId) {
      dispatch(toggleAppLoading(true));
      const info = {
        campaignId: blockId,
        comment: reason,
      };
      const res = await rejectCampaign(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: "Campaign info updated",
          success: true,
        };
        dispatch(setToaster(toasterData));
        handleClose();
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    }
  };

  const handleBlockCampaign = async () => {
    const valid = validateReason();

    if (!valid) {
      return;
    }
    if (blockId) {
      dispatch(toggleAppLoading(true));
      const info = {
        campaignId: blockId,
        comment: reason,
      };
      const res = await blockCampaign(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: "Campaign info updated",
          success: true,
        };
        dispatch(setToaster(toasterData));
        handleClose();
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    }
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleBlockAction = async () => {
    if (blockType === "reject") {
      await handleRejectCampaign();
    } else if (blockType === "block") {
      await handleBlockChainBlockAction();
      await handleBlockCampaign();
    }
  };

  const handleBlockChainBlockAction = async () => {
    const payload = {
      admin_wallet_address: blockChainData.admin_wallet_address,
      campaign_app_id: blockChainData.campaign_app_id,
      milestone_app_id: blockChainData.milesstone_app_id,
    };
    const response = await rejectCampaignBlockchain(payload);
    if (!response.success) {
      return;
    }
    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));
      const transactionArr = response.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));

      const signed = [];
      signed.push(signedTxn[0]);
      signed.push(signedTxn[1]);
      signed.push(signedTxn[2]);
      signed.push(signedTxn[3]);
      // signed.push(signedTxns[3].blob);
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      dispatch(setAppLoadingText("waiting for transaction confirmation"));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );
      if (confirmedTxn) {
        const toastPaylaod = {
          text: "Campaign blocked successfully",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
      }
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box onClick={(e) => e.stopPropagation()} sx={modalContainer}>
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              {blockType === "block"
                ? "Block"
                : blockType === "reject"
                ? "Reject"
                : ""}
            </Typography>
          </Box>
          <Box sx={ContentStyle}>
            <TextField
              fullWidth
              hiddenLabel
              multiline
              minRows={2}
              type={"text"}
              placeholder={
                blockType === "reject" ? "Reason to reject" : "Reason to block"
              }
              variant="outlined"
              onChange={handleReasonChange}
              error={invalidReason}
              sx={textArea}
              helperText={
                invalidReason && "Please enter a reason for your action"
              }
            />

            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={3}
              mb={3}
            >
              <>
                <Button
                  onClick={handleShowWalletOptions}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handleBlockAction}
                  />
                )}
              </>
              <Button onClick={handleClose} variant="contained" color="error">
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default BlockReasonModal;
