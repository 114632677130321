import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useAppSelector } from "../store";

function LoadingScreen() {
  const open = useAppSelector(
    (state) => state.notifAndLoadingReducer.appLoading
  );
  const text = useAppSelector(
    (state) => state.notifAndLoadingReducer.appLoadingText
  );
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: "99999999999999",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "20px",
        flexDirection: "column",
      }}
      open={open}
    >
      <CircularProgress color="inherit" />
      {text.length > 0 && <h3>{text}</h3>}
    </Backdrop>
  );
}

export default LoadingScreen;
