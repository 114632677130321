import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  editCategory: boolean;
  editCategoryType: string;
  editCategoryName: string;
  editCategorySlug: string;
  editCategoryId: string;
}

interface addCategoryModal {
  addCategory: boolean;
  addCategoryType: string;
}

interface deleteCategoryModal {
  deleteCategory: boolean;
  deleteCategoryId: string;
  deleteType: string;
}

interface blockModal {
  blockUserCampaignType: string;
  blockUserCampaignId: string;
  blockUserCampaign: boolean;
  admin_wallet_address: string;
  campaign_app_id: string;
  milesstone_app_id: [] | any;
}

interface rejectMilestoneModal {
  rejectMilestoneAppId: number | undefined;
  rejectCampaignAppId: number | undefined;
  rejectMilestoneNumber: number | undefined;
  rejectMilestone: boolean;
}

interface rejectGrantModal {
  grantId: string | undefined;
  rejectGrantAppId: number | undefined;
  rejectGrant: boolean;
  blockGrant: boolean;
}
interface createGoalModal {
  createGoal: boolean;
  editGoal: boolean;
  category: string;
  goalName: string;
  goalId: string;
}

// Define the initial state using that type
const initialState: CounterState &
  deleteCategoryModal &
  addCategoryModal &
  blockModal &
  rejectMilestoneModal &
  rejectGrantModal &
  createGoalModal = {
  editCategory: false,
  editCategoryType: "",
  editCategoryName: "",
  editCategoryId: "",
  editCategorySlug: "",
  addCategory: false,
  addCategoryType: "",
  deleteCategory: false,
  deleteCategoryId: "",
  deleteType: "",
  blockUserCampaignType: "",
  blockUserCampaignId: "",
  blockUserCampaign: false,
  admin_wallet_address: "",
  campaign_app_id: "",
  milesstone_app_id: [],
  rejectMilestoneAppId: undefined,
  rejectCampaignAppId: undefined,
  rejectMilestoneNumber: undefined,
  rejectMilestone: false,
  createGoal: false,
  editGoal: false,
  category: "",
  goalName: "",
  goalId: "",
  grantId: undefined,
  rejectGrant: false,
  blockGrant: false,
  rejectGrantAppId: undefined,
};

export const modalSlice = createSlice({
  name: "ModalSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setEditCategoryModal: (state, action: PayloadAction<CounterState>) => {
      state.editCategory = action.payload.editCategory;
      state.editCategoryName = action.payload.editCategoryName;
      state.editCategorySlug = action.payload.editCategorySlug;
      state.editCategoryId = action.payload.editCategoryId;
      state.editCategoryType = action.payload.editCategoryType;
    },
    setAddCategoryModal: (state, action: PayloadAction<addCategoryModal>) => {
      state.addCategory = action.payload.addCategory;
      state.addCategoryType = action.payload.addCategoryType;
    },
    setDeleteCategoryModal: (
      state,
      action: PayloadAction<deleteCategoryModal>
    ) => {
      state.deleteCategory = action.payload.deleteCategory;
      state.deleteCategoryId = action.payload.deleteCategoryId;
      state.deleteType = action.payload.deleteType;
    },
    setBlockUserCampaignModal: (state, action: PayloadAction<blockModal>) => {
      state.blockUserCampaign = action.payload.blockUserCampaign;
      state.blockUserCampaignId = action.payload.blockUserCampaignId;
      state.blockUserCampaignType = action.payload.blockUserCampaignType;
      state.admin_wallet_address = action.payload.admin_wallet_address;
      state.campaign_app_id = action.payload.campaign_app_id;
      state.milesstone_app_id = action.payload.milesstone_app_id;
    },
    setRejectMilestoneModal: (
      state,
      action: PayloadAction<rejectMilestoneModal>
    ) => {
      state.rejectMilestoneAppId = action.payload.rejectMilestoneAppId;
      state.rejectMilestone = action.payload.rejectMilestone;
      state.rejectCampaignAppId = action.payload.rejectCampaignAppId;
      state.rejectMilestoneNumber = action.payload.rejectMilestoneNumber;
    },
    setCreateGoalModal: (state, action: PayloadAction<createGoalModal>) => {
      state.createGoal = action.payload.createGoal;
      state.editGoal = action.payload.editGoal;
      state.category = action.payload.category;
      state.goalName = action.payload.goalName;
      state.goalId = action.payload.goalId;
    },
    setRejectGrantModal: (state, action: PayloadAction<rejectGrantModal>) => {
      state.grantId = action.payload.grantId;
      state.rejectGrant = action.payload.rejectGrant;
      state.blockGrant = action.payload.blockGrant;
      state.rejectGrantAppId = action.payload.rejectGrantAppId;
    },
  },
});

export const {
  setEditCategoryModal,
  setAddCategoryModal,
  setDeleteCategoryModal,
  setBlockUserCampaignModal,
  setRejectMilestoneModal,
  setCreateGoalModal,
  setRejectGrantModal,
} = modalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default modalSlice.reducer;
