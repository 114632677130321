import {
  Box,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  FormControlLabel,
  FormLabel,
  Checkbox,
  Button,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import { useAppDispatch, useAppSelector } from "../../../store";
import { setCreateGoalModal } from "../../../store/slices/ModalSlice";
import { useEffect, useRef, useState } from "react";
import { createGoal, UpdateGoal } from "../../../requests/authRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CreateGoal({ hendleFetchGoals }: any) {
  const goalReducer = useAppSelector((state) => state.modalReducer);
  const [category, setCategory] = useState<string>("");
  const [goalName, setGoalName] = useState<string>("");
  const dispatch = useAppDispatch();
  const handleCloseCreateGoal = () => {
    const payload = {
      createGoal: false,
      editGoal: false,
      category: "",
      goalName: "",
      goalId: "",
    };
    setCategory("");
    setGoalName("");
    dispatch(setCreateGoalModal(payload));
  };
  useEffect(() => {
    setCategory(goalReducer.category);
    setGoalName(goalReducer.goalName);
  }, [goalReducer.category, goalReducer.goalName]);
  const handleCategoryChange = (e: SelectChangeEvent) => {
    setCategory(e.target.value);
  };
  const handleCreateGoal = async () => {
    if (!goalName.trim() || !category.trim()) {
      const toasterData = {
        active: true,
        text: "Please fill all fields!",
        success: false,
      };

      dispatch(setToaster(toasterData));
      return true;
    }
    const payload = {
      goalName: goalName,
      category: category,
      ...(goalReducer.editGoal ? { goalId: goalReducer.goalId } : {}),
    };
    let res;
    dispatch(toggleAppLoading(true));
    if (goalReducer.createGoal) {
      res = await createGoal(payload);
    } else {
      res = await UpdateGoal(payload);
    }
    dispatch(toggleAppLoading(false));
    if (res.success) {
      hendleFetchGoals();
      setCategory("");
      setGoalName("");
      const payload = {
        createGoal: false,
        editGoal: false,
        category: "",
        goalName: "",
        goalId: "",
      };
      const toasterData = {
        active: true,
        text: goalReducer.createGoal
          ? "Goal created successfully"
          : "Goal updated successfully",
        success: true,
      };

      dispatch(setToaster(toasterData));
      dispatch(setCreateGoalModal(payload));
    }
  };
  return (
    <div>
      <Box>
        <div>
          <Modal
            open={goalReducer.createGoal || goalReducer.editGoal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{ width: "52ch", borderRadius: "16px" }}>
              <Typography
                style={{ textAlign: "left", fontSize: "2rem" }}
                variant="h3"
              >
                {goalReducer.createGoal ? "Create New Goal" : "Edit Goal"}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
                Enter your basic details to{" "}
                {goalReducer.createGoal ? "add" : "edit"} goal
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Goal Category
                </InputLabel>
                <Select
                  displayEmpty
                  defaultValue={goalReducer.category}
                  onChange={handleCategoryChange}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Select Goal Category"
                >
                  <MenuItem value={"environmental"}>Environmental</MenuItem>
                  <MenuItem value={"social"}>Social</MenuItem>
                  <MenuItem value={"governance"}>Governance</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Goal Name"
                variant="outlined"
                sx={{ mt: 2, mb: 2, width: "100%" }}
                defaultValue={goalReducer.goalName}
                onChange={(e) => {
                  setGoalName(e.target.value);
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "290px",
                  marginTop: 3,
                  marginBottom: 3,
                }}
              >
                <Button
                  onClick={handleCloseCreateGoal}
                  variant="contained"
                  color="error"
                  className="round-button"
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleCreateGoal}
                  variant="contained"
                  color="primary"
                  className="round-button"
                >
                  {goalReducer.createGoal ? "Create" : "Update"}
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </Box>
    </div>
  );
}
export default CreateGoal;
