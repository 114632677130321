import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { InputFiles } from "typescript";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CancelIcon from "@mui/icons-material/Cancel";
import { filename, PhotoUpload } from "./styles";
import { useAppDispatch, useAppSelector } from "../../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { mintNft } from "../../../../requests/authRequest";
import { useWallet } from "@txnlab/use-wallet";
import { client } from "../../../../algorand";
import { createNftAdmin, handleIPFSUpload } from "../../../../blockchain";
import algosdk from "algosdk";
import { create } from "ipfs-http-client";
import Wallets from "../../../../algorandWallet";

// @ts-ignore
const ipfsClient = create("https://ipfs.infura.io:5001/api/v0");

function MintNft() {
  const { activeAccount, providers, signTransactions } = useWallet();
  // related to image upload
  const [file, setFile] = useState<InputFiles | undefined>();
  const [fileName, setFileName] = useState<string>("");
  const [fileSelected, setFileSelected] = useState<boolean>(false);
  const [fileType, setFileType] = useState<string>("");
  const [videoUrl, setVideoUrl] = useState<string>("");
  const fileRef = useRef<HTMLElement>();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  // related to nft title
  const [title, setTitle] = useState<string>("");
  const [invalidTitle, setInvalidTitle] = useState<boolean>(false);
  const [errorTitle, setErrorTitle] = useState<string>("");

  // related to nft description
  const [description, setDescription] = useState<string>("");
  const [invalidDescription, setInvalidDescription] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>("");

  const user = useAppSelector((state) => state.userReducer);

  // related to img
  const [emptyImage, setEmptyImage] = useState<boolean>();

  const dispatch = useAppDispatch();

  const handleUploadChange = (e: any) => {
    const tempFile = e.target.files;
    let tempFileType: string = tempFile[0].name
      .split(".")
      [tempFile[0].name.split(".").length - 1].toLowerCase();
    let isImage: boolean = false;
    // this part set the file type to display
    if (tempFileType && tempFileType.length > 0) {
      if (
        tempFileType === "jpeg" ||
        tempFileType === "jpg" ||
        tempFileType === "png" ||
        tempFileType === "gif" ||
        tempFileType === "svg"
      ) {
        setFileType("image");
        isImage = true;
      } else if (tempFileType === "mp4" || tempFileType === "webm") {
        setFileType("video");
        isImage = false;
      } else {
        clearfileData();
        const toastPaylaod = {
          text: "Please select a JPEG, JPG, PNG, GIF, SVG, MP4, WEBM file",
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        return;
      }
    }
    // this part actually accpet and set the file data for the form
    if (
      tempFile[0] !== undefined &&
      tempFile[0].name.length > 0 &&
      Math.floor(tempFile[0].size / 1000000) < 10
    ) {
      setFileSelected(true);
      setFile(tempFile[0]);
      setFileName(tempFile[0].name);
      if (isImage && fileRef && fileRef.current) {
        fileRef.current.style.backgroundImage = `url(${URL.createObjectURL(
          tempFile[0]
        )})`;
      } else {
        setVideoUrl(URL.createObjectURL(tempFile[0]));
      }
    }

    // this one checks for a valid size 10mb
    if (tempFile[0].size / 1000000 > 10) {
      clearfileData();
      const toastPaylaod = {
        text: "Please select a file less then 10MB",
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      return;
    }
  };

  const clearfileData = () => {
    setFile(undefined);
    setFileName("");
    setFileSelected(false);
    setFileType("");
    if (fileRef && fileRef.current) {
      fileRef.current.style.backgroundImage = "none";
    }
  };

  const validateForm = () => {
    let validity = true;
    // for title
    if (title.trim().length <= 0) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Please enter the title");
    } else if (title.length > 5) {
      validity = false;
      setInvalidTitle(true);
      setErrorTitle("Title must be 5 char or smaller");
    } else {
      setInvalidTitle(false);
      setErrorTitle("");
    }

    // for description
    if (description.trim().length <= 0 || description.trim().length > 3000) {
      validity = false;
      setInvalidDescription(true);
      setErrorDescription("Please enter the description of length 1 to 3000");
    } else {
      setInvalidDescription(false);
      setErrorDescription("");
    }

    // for file selected
    if (!fileSelected || file === undefined) {
      validity = false;
      setEmptyImage(true);
    } else {
      setEmptyImage(false);
    }

    return validity;
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleBlockchainSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText("Creating NFT"));
    dispatch(setAppLoadingText("uploding to ipfs"));

    let payload = {
      file: file,
      userAppId: user.userAppId,
    };
    const response = await handleIPFSUpload(payload);

    const url = response.data.url;

    const info = {
      app_id: parseInt(user.userAppId),
      user_name: user.name,
      user_type: "admin",
      unit_name: title,
      asset_name: "Cashdillo",
      image_hash: url,
      description: description,
    };

    dispatch(setAppLoadingText("sending data to blockchain"));
    const unsignedTxnRes = await createNftAdmin(info);

    if (unsignedTxnRes.success) {
      try {
        dispatch(setAppLoadingText("signing transaction"));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedTxnRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));

        dispatch(setAppLoadingText("waiting for confirmation"));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();
        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        let assetID = confirmedTxn["asset-index"];
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        const toastPaylaod = {
          text: "NFT minted successfully",
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        clearfileData();
        setTitle("");
        setDescription("");
        return;
      } catch (err: any) {
        const toastPaylaod = {
          text: err.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
      }
    } else {
      const toastPaylaod = {
        text: unsignedTxnRes.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
  };

  const handleSubmit = async () => {
    const valid = validateForm();
    if (!valid) {
      return;
    }
    const info = {
      file: file,
      title: title,
      description: description,
    };
    dispatch(toggleAppLoading(true));
    const res = await mintNft(info);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      const toastPaylaod = {
        text: "NFT minted successfully",
        success: true,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      clearfileData();
      setTitle("");
      setDescription("");
    } else {
      const toastPaylaod = {
        text: res.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
    }
  };

  return (
    <Box mb={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h5" align="left" mb={2}>
          Mint NFT
        </Typography>
      </Box>
      <Box>
        <Box ref={fileRef} sx={PhotoUpload} style={{ position: "relative" }}>
          {fileName?.length > 0 && (
            <IconButton onClick={clearfileData}>
              <CancelIcon />
            </IconButton>
          )}

          {fileType === "video" && file !== undefined && (
            <video width={"100%"} src={videoUrl} controls />
          )}
        </Box>
        {emptyImage && (
          <FormHelperText
            sx={{ marginBottom: 3 }}
            className="css-l8pll-MuiFormHelperText-root Mui-error"
          >
            Please select a file to upload
          </FormHelperText>
        )}
        <Typography sx={filename}>
          {fileName.length > 30 ? `${fileName.slice(0, 27)}...` : fileName}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          <label htmlFor="contained-button-file">
            <input
              style={{ display: "none" }}
              className="inputfile"
              accept=".jpeg, .png, .mp4, .webm, .jpg, .svg, .gif"
              id="contained-button-file"
              onChange={handleUploadChange}
              onClick={(e: any) => {
                e.target.value = "";
              }}
              multiple={false}
              type="file"
            />
            <Button
              variant="text"
              component="span"
              className="uploadButton"
              startIcon={<FileUploadIcon />}
            >
              file Upload
            </Button>
          </label>
        </Stack>

        <TextField
          sx={{ marginBottom: 3 }}
          fullWidth
          hiddenLabel
          type={"text"}
          placeholder="NFT Symbol"
          variant="outlined"
          onChange={(e) => {
            setTitle(e.target.value.toLocaleUpperCase());
          }}
          value={title}
          error={invalidTitle}
          helperText={invalidTitle && errorTitle}
        />
        <TextField
          sx={{ marginBottom: 3 }}
          fullWidth
          hiddenLabel
          multiline
          minRows={4}
          type={"text"}
          placeholder="Description"
          variant="outlined"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          value={description}
          error={invalidDescription}
          helperText={invalidDescription && errorDescription}
        />
        <>
          <Button
            sx={{ margin: "20px 0px" }}
            onClick={handleShowWalletOptions}
            variant="contained"
          >
            Mint
          </Button>
          {showWalletButtons && (
            <Wallets
              open={showWalletButtons}
              handleClose={() => setShowWalletButtons(false)}
              handleSubmit={handleBlockchainSubmit}
            />
          )}
        </>
      </Box>
    </Box>
  );
}

export default MintNft;
