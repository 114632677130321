export const DataGridWrap = {
  height: "500px",
  width: "100%",
  "& .MuiDataGrid-root": {
    border: "none",
    "& .MuiDataGrid-columnHeaders": {
      bgcolor: "primary.buttongrey",
      borderBottom: "none",
      "& .MuiDataGrid-columnHeaderTitle": {
        fontSize: "14px",
      },
    },
    "& .MuiDataGrid-cell": {
      fontSize: "14px",
      padding: "12px 12px",
      wordBreak: "break-word",
      whiteSpace: "normal",
      maxHeight: "inherit !important",
    },
    "& .MuiDataGrid-row": {
      maxHeight: "inherit !important",
      "&:nth-child(odd)": {
        bgcolor: "transparent",
      },
      "&:nth-child(even)": {
        bgcolor: "primary.buttongrey",
      },
    },
  },
};

export const footer = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  marginTop: "15px",
  mt: 4,
};

export const note = {
  display: "flex",
  alignItems: "right",
  marginTop: "15px",
};

export const constant = {
  position: "relative",
  zIndex: "9",
  cursor: "pointer",
  textDecoration: "none",
  color: "#0d6efd",
};
