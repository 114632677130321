import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

import DashboardRenderArea from "../../components/dashboard/dashboardRenderArea/DashboardRenderArea";
import DashboardNav from "../../components/dashboard/dashboardNav/DashboardNav";
import { useAppSelector } from "../../store";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const userLoggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userLoggedIn) {
      navigate("/login");
    }
  }, [userLoggedIn]);
  return (
    <Box sx={{ display: "flex" }}>
      <DashboardNav setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop: "64px",
          width: { md: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "#f1f3f4",
          minHeight: "calc(100vh - 64px)",
          overflowY: "scroll",
        }}
      >
        <DashboardRenderArea />
      </Box>
    </Box>
  );
}

export default Dashboard;
