export const Sidebar = {
  "& a": {
    textDecoration: "none",
  },
  "& .MuiListItemText-root": {
    color: "#ffffff",
  },
  "& .MuiListItemIcon-root": {
    color: "#ffffff",
  },
  "& .MuiSvgIcon-root": {
    color: "#ffffff",
  },
};
