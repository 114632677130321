import {
  Box,
  Button,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getTemplateData, updateTemplate } from "../../requests/authRequest";
import { useAppDispatch } from "../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { constant, footer, note } from "./style";
import { style } from "../modals/styles";
import React from "react";

interface data {
  id: number;
  templateName: string;
  status: string;
  action: string;
  content: Array<any>;
  dynamicKey: Array<any>;
  templateId: string;
  isDeleted: boolean;
  createdAt: string;
}

function EditTemplates() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [content, setContent] = useState<string>("");
  const [templateData, setTemplateData] = useState<data>();
  const { id } = useParams();
  const [open, setOpen] = React.useState(false);

  const handleChange = (e: any, editor: any) => {
    setContent(editor.getData());
    const data: any = templateData;
    data.content[0].html = editor.getData();
    setTemplateData(data);
  };

  const fetchTemplateData = async () => {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const res = await getTemplateData(id);
    dispatch(toggleAppLoading(false));
    if (res.success) {
      setTemplateData(res?.data);
      setContent(res?.data?.content?.[0]?.html);
    }
  };

  const handleUpdateTemplate = async () => {
    const payload = {
      templateId: id,
      content: templateData?.content,
    };
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const res = await updateTemplate(payload);
    if (res.success) {
      const toasterData = {
        active: true,
        text: "Template updated successfully",
        success: true,
      };
      dispatch(setToaster(toasterData));
    }
    dispatch(toggleAppLoading(false));
  };

  useEffect(() => {
    fetchTemplateData();
  }, []);

  return (
    <Box>
      <Typography variant="h4" align="left" mb={4} fontWeight={600}>
        Edit Email Template
      </Typography>
      <Typography align="left" mb={4} fontWeight={600} sx={constant}>
        <a style={{ textDecoration: "none" }} onClick={() => setOpen(true)}>
          Template constants
        </a>
      </Typography>
      <Box>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={(e: any, editor: any) => {
            handleChange(e, editor);
          }}
        />
      </Box>
      <Box sx={note}>
        <b>*Note:</b> &nbsp;&nbsp;
        <em style={{ color: "rgb(216 107 107)" }}>
          Please do not change constants (text in curly braces).
        </em>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.buttongrey",
            mt: 4,
            mr: 2,
            "&:hover": {
              backgroundColor: "primary.buttongreydark",
            },
            "&:focus": {
              backgroundColor: "primary.buttongreydark",
            },
          }}
          onClick={() => navigate(`/dashboard/templates`)}
        >
          Go Back
        </Button>
        <Button onClick={handleUpdateTemplate} variant="contained" sx={footer}>
          Update Template
        </Button>
      </Box>
      <Modal
        open={open}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} style={{ width: "52ch", borderRadius: "16px" }}>
          <Typography
            style={{ textAlign: "center", fontSize: "2rem" }}
            variant="h4"
          >
            Welcome client screen
          </Typography>
          <Box>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 100 }} aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <b>Constant Name</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>Description</b>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templateData?.dynamicKey.map((row) => (
                    <TableRow
                      key={row.donationId}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>
                      <TableCell align="right">{row?.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "290px",
              marginTop: 3,
              marginBottom: 3,
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              variant="contained"
              color="error"
              className="round-button"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
export default EditTemplates;
