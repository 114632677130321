import {
  Box,
  Typography,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Button,
  IconButton,
} from "@mui/material";
import { LoginWrapper, LoginBox } from "./styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoSmall from "../../../assets/images/cashdillo-logosmall.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LockIcon from "@mui/icons-material/Lock";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { setUserToken } from "../../../store/slices/UserSlice";
import { handleEmailLogin } from "../../../requests/simpleRequest";

function Login() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [invalidPassword, setInvalidPassword] = useState<boolean>(false);

  const [email, setEmail] = useState<string>("");

  const dispatch = useAppDispatch();
  const userLoggedIn = useAppSelector((state) => state.userReducer.loggedIn);
  const navigate = useNavigate();

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (true) {
      dispatch(toggleAppLoading(true));
      const payload = {
        email: email,
        password: password,
      };
      const res: any = await handleEmailLogin(payload);
      if (res.success) {
        const payload = {
          loggedIn: true,
          userToken: res.data.token,
          loginType: "email",
          name: res.data.name,
          userAppId: res.data.userAppId,
        };
        dispatch(setUserToken(payload));
        const toastPaylaod = {
          text: res.data.message,
          success: true,
          active: true,
        };
        dispatch(toggleAppLoading(false));
        dispatch(setToaster(toastPaylaod));
      } else {
        const toastPaylaod = {
          text: res.data.message,
          success: false,
          active: true,
        };
        dispatch(toggleAppLoading(false));
        dispatch(setToaster(toastPaylaod));
      }
    }
    dispatch(toggleAppLoading(false));
  };

  useEffect(() => {
    if (userLoggedIn) {
      navigate("/dashboard");
    }
  }, [userLoggedIn]);

  return (
    <div>
      <Box sx={LoginWrapper}>
        <Box sx={LoginBox}>
          <img src={LogoSmall} className="logo" alt="logo" />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mb: 5,
              justifyContent: "center",
            }}
          >
            <Typography variant="h4">Welcome to </Typography>
            <Typography variant="h4" fontWeight={600}>
              &nbsp;Cashdillo&nbsp;
            </Typography>
            <Typography variant="h4">Admin</Typography>
          </Box>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-weight"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton sx={{ cursor: "default" }}>
                    <AccountCircleIcon />
                  </IconButton>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              placeholder="username"
              onChange={handleEmailChange}
            />
          </FormControl>
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={handlePasswordChange}
              error={invalidPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    // edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="password"
            />
          </FormControl>
          <Box sx={{ textAlign: "left" }}>
            <Button
              variant="text"
              startIcon={<LockIcon />}
              sx={{ paddingLeft: "0px", color: "#a7abab" }}
            >
              Forgot Password?
            </Button>
          </Box>
          <Link to="/dashboard" style={{ textDecoration: "none" }}>
            <Button
              variant="contained"
              sx={{
                width: "100%",
                textTransform: "uppercase",
                mt: 3,
                fontSize: "18px",
              }}
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Link>
        </Box>
      </Box>
    </div>
  );
}

export default Login;
