import { Modal, Box, Button } from "@mui/material";
import { useWallet } from "@txnlab/use-wallet";
import CancelIcon from "@mui/icons-material/Cancel";

export default function Wallets({ open, handleClose, handleSubmit }: any) {
  const { providers, activeAccount } = useWallet();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          height: "100vh",
          outline: " 0px",
          overflowY: "scroll",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
          paddingTop: "0px",
          "@media(max-height:700px)": {
            alignItems: "baseline",
          },
        }}
      >
        <Box
          sx={{
            maxWidth: "600px",
            width: "98%",
            bgcolor: "#eef0f0",
            borderRadius: "12px",
            margin: "32px",
            position: "relative",
            overflowY: "auto",
            display: "inline-block",
            verticalAlign: "middle",
            textAlign: "left",
            padding: 4,
          }}
        >
          <CancelIcon
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <div>
            {providers?.map((provider) => (
              <div key={"provider-" + provider.metadata.id}>
                <h4>
                  <img
                    width={30}
                    height={30}
                    alt=""
                    src={provider.metadata.icon}
                  />
                  {provider.metadata.name} {provider.isActive && "[active]"}
                </h4>
                <div>
                  <button
                    onClick={provider.connect}
                    disabled={provider.isConnected}
                  >
                    Connect
                  </button>
                  <button
                    onClick={provider.disconnect}
                    disabled={!provider.isConnected}
                  >
                    Disconnect
                  </button>

                  <div>
                    {provider.isActive && provider.accounts.length && (
                      <select
                        value={activeAccount?.address}
                        onChange={(e) =>
                          provider.setActiveAccount(e.target.value)
                        }
                      >
                        {provider.accounts.map((account) => (
                          <option key={account.address} value={account.address}>
                            {account.address}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Box sx={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              variant="contained"
              onClick={() => {
                handleSubmit();
                handleClose();
              }}
              disabled={activeAccount === null}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
