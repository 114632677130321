import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  ContentStyle,
  modalContainer,
  modalWrapper,
  titleStyle,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../store";
import { setAddCategoryModal } from "../../store/slices/ModalSlice";
import { TextField } from "@mui/material";
import {
  createCampaignCategory,
  createFundingCategory,
} from "../../requests/authRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";

const AddCategoryModal = () => {
  const open = useAppSelector((state) => state.modalReducer.addCategory);
  const dispatch = useAppDispatch();
  const categoryType = useAppSelector(
    (state) => state.modalReducer.addCategoryType
  );

  const [category, setCategory] = useState<string>("");
  const [invalidCategory, setInvalidCategory] = useState<boolean>(false);
  const [slug, setSlug] = useState<string>("");
  const [invalidSlug, setInvalidSlug] = useState<boolean>(false);

  const handleClose = () => {
    setCategory("");
    setSlug("");
    setInvalidCategory(false);
    setInvalidSlug(false);
    const payload = {
      addCategory: false,
      addCategoryType: "",
    };
    dispatch(setAddCategoryModal(payload));
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const handleSlugChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSlug(e.target.value);
  };

  const validateValues = () => {
    let res = true;
    if (category.trim().length === 0 || category.trim().length > 20) {
      setInvalidCategory(true);
      res = false;
    } else {
      setInvalidCategory(false);
    }

    if (slug.trim().length === 0 || slug.trim().length > 20) {
      setInvalidSlug(true);
      res = false;
    } else {
      setInvalidSlug(false);
    }

    return res;
  };

  const validateFundingValue = () => {
    let res = true;
    if (category.trim().length === 0 || category.trim().length > 20) {
      setInvalidCategory(true);
      res = false;
    } else {
      setInvalidCategory(false);
    }

    if (slug.trim().length === 0 || slug.trim().length > 20) {
      setInvalidSlug(true);
      res = false;
    } else {
      setInvalidSlug(false);
    }

    return res;
  };

  const handleCreateCategory = async () => {
    if (categoryType.length > 0 && categoryType === "campaign") {
      const proceed = validateValues();
      if (!proceed) {
        return;
      }
      dispatch(toggleAppLoading(true));
      const info = {
        title: category,
        slug: slug,
      };
      const res = await createCampaignCategory(info);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        const toastPayload = {
          active: true,
          text: "Category created successfully",
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    } else if (categoryType.length > 0 && categoryType === "funding") {
      const proceed = validateFundingValue();
      if (!proceed) {
        return;
      }
      dispatch(toggleAppLoading(true));
      const info = {
        title: category,
      };
      const res = await createFundingCategory(info);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        const toastPayload = {
          active: true,
          text: "Category created successfully",
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    }
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box onClick={(e) => e.stopPropagation()} sx={modalContainer}>
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              Add New Category
            </Typography>
          </Box>
          <Box sx={ContentStyle}>
            <TextField
              fullWidth
              hiddenLabel
              type={"text"}
              id="filled-hidden-label-small"
              placeholder="Category Name"
              variant="outlined"
              onChange={handleCategoryChange}
              error={invalidCategory}
              helperText={
                invalidCategory &&
                "Please enter a category name of length 1 to 20 characters"
              }
            />
            <TextField
              fullWidth
              hiddenLabel
              type={"text"}
              id="filled-hidden-label-small"
              placeholder="Category Slug"
              variant="outlined"
              onChange={handleSlugChange}
              error={invalidSlug}
              helperText={
                invalidSlug &&
                "Please enter a Slug name of length 1 to 20 characters"
              }
            />

            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={3}
              mb={3}
            >
              <Button
                onClick={handleCreateCategory}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              <Button onClick={handleClose} variant="contained" color="error">
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddCategoryModal;
