export const PhotoUpload = {
  maxWidth: "300px",
  maxHeight: "300px",
  width: "100%",
  height: "300px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "contain",
  display: "flex",

  backgroundColor: "#fff",
  "& .MuiIconButton-root": {
    position: "absolute",
    right: "0px",
    top: "0px",
    zIndex: "2",
  },
};

export const filename = {
  fontSize: "12px",
  textAlign: "left",
  margin: "10px 0px",
};
