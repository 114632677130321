import { Box, Grid, Typography, Stack, Button, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  approveMilestone,
  getMilestoneDataById,
} from "../../../../requests/authRequest";
import { useAppDispatch } from "../../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { pdfjs } from "react-pdf";
import { useWallet } from "@txnlab/use-wallet";
import { client } from "../../../../algorand";
import { setRejectMilestoneModal } from "../../../../store/slices/ModalSlice";
import { approveMilestoneBlockchain } from "../../../../blockchain";
import Wallets from "../../../../algorandWallet";
import algosdk from "algosdk";
pdfjs.GlobalWorkerOptions.workerSrc = "/js/pdf.worker.min.js";

const URL = process.env.REACT_APP_URL;

interface milestone {
  campaign: any;
  milestone: {
    status: string;
    title: string;
    definition: string;
    deadline: string | Date;
    deliverable: string;
    milestoneAppId: string;
    isReforestationProject: boolean;
    campaign: {
      status: string;
      milestones: Array<string>;
      title: string;
      description: string;
      endDate: any;
      goalAmount: number;
      campaignAppId: string;
      campaignId: string;
    };
    milestones: {
      endDate: any;
    };
    createdBy: {
      name: string;
      userId: string;
    };
    expectedCostPerUnit: string;
    expectedResults: string;
    expectedYearlyoutput: number;
    standardReport: {
      objectiveMeet: boolean;
      evidenceFiles: Array<string>;
      supportingDocs: Array<string>;
      comments: string;
      updatedAt: string | Date;
    };
    milestoneId: string;
  };
  milestoneNumber: number;
}

function ViewMilestone() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [milestoneData, setMilestoneData] = useState<milestone>();
  const { milestoneId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const [isVideo, setIsVideo] = useState<boolean>();

  const fetchMilestoneData = async () => {
    const info = {
      milestoneId: milestoneId,
    };
    dispatch(toggleAppLoading(true));
    const res = await getMilestoneDataById(info);
    dispatch(toggleAppLoading(false));

    if (res.success) {
      setMilestoneData(res.data);
    } else {
      const toastPaylaod = {
        active: true,
        success: false,
        text: res.data.message,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };
  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  const handleIsVideo = () => {
    const extenstion = milestoneData?.milestone.standardReport.evidenceFiles[0]
      .split(".")
      [
        milestoneData?.milestone.standardReport.evidenceFiles[0].split(".")
          .length - 1
      ].toLowerCase()
      .trim();

    if (extenstion === "mp4" || extenstion === "webm") {
      setIsVideo(true);
    } else {
      setIsVideo(false);
    }
  };

  const handleAcceptMilestoneBlockchain = async () => {
    if (!milestoneData) {
      return {
        success: false,
      };
    }

    const info = {
      campaign_app_id: milestoneData.milestone.campaign.campaignAppId, // campaign id of the milestones
      milestone_number: milestoneData.milestoneNumber + 1, // milestone number of the milestone that has to be started next
      admin_wallet_address: activeAccount?.address,
      milestone_app_id: milestoneData.milestone.milestoneAppId, // milestone app id of the milestone that has to be ended
      note: "approve",
    };

    const unsignedTxn = await approveMilestoneBlockchain(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: unsignedTxn.data[0].message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));

      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));
      // Submit the transaction
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      // Wait for confirmation
      dispatch(setAppLoadingText("waiting for transaction confirmation"));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      return {
        succcess: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleAcceptMilestone = async () => {
    if (!milestoneData) {
      return {
        success: false,
      };
    }
    // dispatch(toggleAppLoading(true));
    const res = await handleAcceptMilestoneBlockchain();
    if (res.succcess) {
      const info = {
        milestoneId: milestoneData.milestone.milestoneId,
      };
      const res2 = await approveMilestone(info);
      if (res2.success) {
        await fetchMilestoneData();
        dispatch(toggleAppLoading(false));
        dispatch(setAppLoadingText(""));
        const toastPaylaod = {
          text: "Milestone approved successfully",
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
      } else {
        dispatch(toggleAppLoading(false));
        dispatch(setAppLoadingText(""));
        const toastPaylaod = {
          text: res2.data.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
      }
    }
  };

  const handleRejectMilestone = () => {
    if (milestoneData && milestoneData.milestone) {
      const payload = {
        rejectMilestone: true,
        rejectMilestoneAppId: parseInt(milestoneData.milestone.milestoneAppId),
        rejectCampaignAppId: parseInt(
          milestoneData.milestone.campaign.campaignAppId
        ),
        rejectMilestoneNumber: milestoneData.milestoneNumber,
      };
      dispatch(setRejectMilestoneModal(payload));
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    fetchMilestoneData();
  }, [milestoneId]);

  useEffect(() => {
    if (milestoneData && milestoneData.milestone) {
      handleIsVideo();
    }
  }, [milestoneData]);
  return (
    <>
      {milestoneData && (
        <Box>
          <Typography variant="h4" align="left" mb={4} fontWeight={600}>
            View Milestone Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Title
                  </Typography>
                  <Typography variant="h6" align="left">
                    {milestoneData.milestone.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Description
                  </Typography>
                  <Typography variant="h6" align="left">
                    {milestoneData.milestone.definition}
                  </Typography>
                </Box>
                {milestoneData.milestone?.isReforestationProject == true && (
                  <Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        ProjectOutput
                      </Typography>
                      <Typography variant="h6" align="left">
                        {milestoneData.milestone.expectedYearlyoutput}
                      </Typography>
                    </Box>
                    <Box mb={1}>
                      <Typography variant="h5" fontWeight={"600"} align="left">
                        CostPerUnit($)
                      </Typography>
                      <Typography variant="h6" align="left">
                        {milestoneData.milestone.expectedCostPerUnit}
                      </Typography>
                    </Box>
                    <div
                      style={{
                        minHeight: "50%",
                        fontSize: "20px",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                      }}
                    >
                      <Box mb={1}>
                        <Typography
                          variant="h5"
                          fontWeight={"600"}
                          align="left"
                        >
                          Expected Results
                        </Typography>
                        <Typography variant="h6" align="left">
                          {milestoneData.milestone.expectedResults}
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                )}
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Milestone Number
                  </Typography>
                  <Typography variant="h6" align="left">
                    {milestoneData.milestoneNumber}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Submitted Comment
                  </Typography>
                  <Typography variant="h6" align="left">
                    {milestoneData.milestone.standardReport.comments}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Objective Met ?
                  </Typography>
                  <Typography variant="h6" align="left">
                    {milestoneData.milestone.standardReport.objectiveMeet
                      ? "Yes"
                      : "No"}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Submisson Deadline
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(milestoneData.milestone.deadline).format(
                      "MMM DD YYYY"
                    )}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Campaign
                  </Typography>
                  <Link
                    to={`/dashboard/campaign/${milestoneData.milestone.campaign.campaignId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {milestoneData.milestone.campaign.title}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Typography
                    variant="h5"
                    fontWeight={"600"}
                    align="left"
                    mb={1}
                  >
                    Supporting Document
                  </Typography>
                  <a
                    href={`${URL}/api/file/${milestoneData.milestone.standardReport.supportingDocs[0]}`}
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography variant="h6" align="left">
                      Click to download
                    </Typography>
                  </a>
                </Box>
              </Stack>
            </Grid>
            <Grid item lg={8} md={6}>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  {isVideo ? " Submitted Video" : "Submitted Image"}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={10} lg={10}>
                    {isVideo ? (
                      <Typography variant="h6" align="left">
                        <video
                          style={
                            milestoneData.milestone.standardReport
                              .evidenceFiles[0]
                              ? { maxWidth: "98%" }
                              : { display: "none" }
                          }
                          src={`${URL}/api/file/${milestoneData.milestone.standardReport.evidenceFiles[0]}`}
                          controls
                          width={"500px"}
                          height={"auto"}
                          autoPlay={false}
                        />
                      </Typography>
                    ) : (
                      <img
                        style={{
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          width: "500px",
                          height: "500px",
                          cursor: "pointer",
                        }}
                        src={`${URL}/api/file/${milestoneData.milestone.standardReport.evidenceFiles[0]}`}
                        id={`${URL}/api/file/${milestoneData.milestone.standardReport.evidenceFiles[0]}`}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.buttongrey",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary.buttongreydark",
                    },
                    "&:focus": {
                      backgroundColor: "primary.buttongreydark",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>

                {milestoneData.milestone.status === "report-submitted" && (
                  <>
                    <>
                      {new Date().setHours(0, 0, 0, 0) >
                      new Date(milestoneData.milestone.deadline).setHours(
                        0,
                        0,
                        0,
                        0
                      ) ? (
                        <Tooltip title="Milestone end date is expired now">
                          <Button
                            variant="contained"
                            color={"primary"}
                            sx={{
                              backgroundColor: "primary.buttongrey",
                              mt: 4,
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "primary.buttongreydark",
                              },
                              "&:focus": {
                                backgroundColor: "primary.buttongreydark",
                              },
                            }}
                          >
                            Approve
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          disabled={
                            new Date().setHours(0, 0, 0, 0) >
                            new Date(milestoneData.milestone.deadline).setHours(
                              0,
                              0,
                              0,
                              0
                            )
                          }
                          variant="contained"
                          color={"primary"}
                          sx={{ mt: 4, mr: 2 }}
                          onClick={handleShowWalletOptions}
                        >
                          Approve
                        </Button>
                      )}

                      {showWalletButtons && (
                        <Wallets
                          open={showWalletButtons}
                          handleClose={() => setShowWalletButtons(false)}
                          handleSubmit={() => handleAcceptMilestone()}
                        />
                      )}
                    </>
                    <Button
                      variant="contained"
                      color={"error"}
                      sx={{ mt: 4 }}
                      onClick={() => handleRejectMilestone()}
                    >
                      Reject
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}

export default ViewMilestone;
