import React, { useEffect } from "react";
import axios, { Axios, AxiosRequestHeaders } from "axios";
import { useAppDispatch, useAppSelector } from "../store";
import { setUserToken } from "../store/slices/UserSlice";

const baseURL = process.env.REACT_APP_URL;

export let axiosInstance: Axios;

function AuthRequestInterceptor() {
  const token = useAppSelector((state) => state.userReducer.userToken);

  let headers: AxiosRequestHeaders = {};

  const dispatch = useAppDispatch();

  headers.Authorization = `Bearer ${token}`;

  useEffect(() => {
    axiosInstance = axios.create({
      baseURL: baseURL,
      headers,
    });

    axiosInstance.interceptors.response.use(
      (response) =>
        new Promise((resolve, reject) => {
          resolve(response);
        }),
      (error) => {
        if (!error.response) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        if (
          error.response.status === 401 &&
          (error.response.data.message === "User session has been exipred" ||
            error.response.data.message ===
              "User session expired, Please log in again!" ||
            error.response.data.message === "User is not Authenticated.")
        ) {
          const payload = {
            loggedIn: false,
            userToken: "",
            loginType: "",
            registerUserType: "",
            name: "",
            userAppId: "",
          };

          dispatch(setUserToken(payload));
        } else {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }
      }
    );
  }, [token]);

  return <></>;
}

export default AuthRequestInterceptor;
