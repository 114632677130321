import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import FeedIcon from "@mui/icons-material/Feed";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import React, { useEffect, useState } from "react";
import { getDashboardCount } from "../../../requests/authRequest";
import algorand from "../../../assets/images/Algorand.png";
import algo from "../../../assets/images/algo.png";

function DashboardHome() {
  const [users, setUsers] = useState<number>(0);
  const [campaigns, setCampaigns] = useState<number>(0);
  const [donors, setDonors] = useState<number>(0);
  const [donations, setDonations] = useState<number>(0);
  const [withdrawls, setWithdrawls] = useState<number>(0);

  const fetchData = async () => {
    const res = await getDashboardCount();
    if (res.success) {
      setUsers(res.data.totalUsers);
      setCampaigns(res.data.totalCampaigns);
      setDonors(res.data.totalDonors);
      setDonations(res.data.totalDonation);
      setWithdrawls(res.data.totalWithdrawal);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Typography variant="h5" align="left" mb={2}>
        Dashboard
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: "0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec",
              borderRadius: "12px",
              border: "1px solid #4caf50",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80px" }}>
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "1px solid #4caf50",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <GroupIcon sx={{ fontSize: "40px", color: "#4caf50" }} />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(100% - 80px)", textAlign: "right" }}>
                  <Typography
                    variant="h6"
                    mb={1}
                    fontWeight={500}
                    sx={{ color: "#4caf50" }}
                  >
                    Total Users
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#4caf50" }}>
                    {users}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: "0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec",
              borderRadius: "12px",
              border: "1px solid #ba68c8",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80px" }}>
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "1px solid #ba68c8",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FeedIcon sx={{ fontSize: "40px", color: "#ba68c8" }} />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(100% - 80px)", textAlign: "right" }}>
                  <Typography
                    variant="h6"
                    mb={1}
                    fontWeight={500}
                    sx={{ color: "#ba68c8" }}
                  >
                    Total Campaign
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#ba68c8" }}>
                    {campaigns}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: "0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec",
              borderRadius: "12px",
              border: "1px solid #000000",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80px" }}>
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "1px solid #000000",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={algorand} />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(100% - 80px)", textAlign: "right" }}>
                  <Typography
                    variant="h6"
                    mb={1}
                    fontWeight={500}
                    sx={{ color: "#000000" }}
                  >
                    Total Donation
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#000000" }}>
                    {donations}{" "}
                    <img
                      src={algo}
                      style={{ height: "22px", width: "22px" }}
                    ></img>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: "0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec",
              borderRadius: "12px",
              border: "1px solid #03a9f4",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80px" }}>
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "1px solid #03a9f4",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MoneyIcon sx={{ fontSize: "40px", color: "#03a9f4" }} />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(100% - 80px)", textAlign: "right" }}>
                  <Typography
                    variant="h6"
                    mb={1}
                    fontWeight={500}
                    sx={{ color: "#03a9f4" }}
                  >
                    Total Donor
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#03a9f4" }}>
                    {donors}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Card
            sx={{
              boxShadow: "0 0 0 1px #dce3ec, 0 8px 16px 0 #dce3ec",
              borderRadius: "12px",
              border: "1px solid #ef5350",
            }}
          >
            <CardContent>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "80px" }}>
                  <Box
                    sx={{
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      border: "1px solid #ef5350",
                      width: "60px",
                      height: "60px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AccountBalanceWalletIcon
                      sx={{ fontSize: "40px", color: "#ef5350" }}
                    />
                  </Box>
                </Box>
                <Box sx={{ width: "calc(100% - 80px)", textAlign: "right" }}>
                  <Typography
                    variant="h6"
                    mb={1}
                    fontWeight={500}
                    sx={{ color: "#ef5350" }}
                  >
                    Total Withdrawal
                  </Typography>
                  <Typography variant="h3" sx={{ color: "#ef5350" }}>
                    {withdrawls}{" "}
                    <img
                      src={algo}
                      style={{ height: "22px", width: "22px" }}
                    ></img>
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default DashboardHome;
