import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
interface ToasterState {
  active: boolean;
  text: string;
  success: boolean;
}

interface CounterState {
  appLoading: boolean;
  appLoadingText: string;
  toasterState: {
    active: boolean;
    text: string;
    success: boolean;
  };
}

// Define the initial state using that type
const initialState: CounterState = {
  appLoading: false,
  appLoadingText: "",
  toasterState: {
    active: false,
    text: "",
    success: false,
  },
};

export const notifAndLoadingSlice = createSlice({
  name: "NotifAndLoading",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    toggleAppLoading: (state, action: PayloadAction<boolean>) => {
      state.appLoading = action.payload;
    },
    setAppLoadingText: (state, action: PayloadAction<string>) => {
      state.appLoadingText = action.payload;
    },
    setToaster: (state, action: PayloadAction<ToasterState>) => {
      state.toasterState.active = action.payload.active;
      state.toasterState.text = action.payload.text;
      state.toasterState.success = action.payload.success;
    },
  },
});

export const { toggleAppLoading, setToaster, setAppLoadingText } =
  notifAndLoadingSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default notifAndLoadingSlice.reducer;
