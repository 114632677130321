import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridWrap, footer, SearchRow } from "./styles";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../store";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { toggleAppLoading } from "../../../../store/slices/LoadinAndNotifSlice";
import { getGrants } from "../../../../requests/authRequest";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "use-debounce";
import CustomNoRowsOverlay from "../../../table/CustomNoRowOverlay";
interface grantRow {
  id: number;
  status: string;
  title: string;
  deadline: string | Date;
  grantType: string;
}

function CompletedGrants() {
  const [grants, setGrants] = useState<Array<grantRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const timeout = useRef<any>();
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "title",
      headerName: "Grant",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "grantType",
      headerName: "Grant Type",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "Deadline",
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.endDate).format("DD-MMM-YYYY"),
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/dashboard/grant/${params.row.grantId}`);
        };
        return (
          <Box>
            <IconButton onClick={handleView}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchAllGrants = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 8,
      status: "completed",
    };
    const res = await getGrants(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }

    if (res.data.totalPages > page) {
      setDisableNext(false);
    }
    setEndPage(res.data.totalPages);
    if (res.success) {
      let tempGrants: Array<grantRow> = [];
      tempGrants = res.data.grants.map((grant: any, index: number) => {
        return { ...grant, id: index + 1 + (res.data.currentPage - 1) * 8 };
      });
      setGrants([...tempGrants]);
    } else {
      setGrants([]);
    }
  };

  const searchGrants = async () => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      // do everything
      setloading(true);
      const data = {
        page: page,
        limit: 8,
        status: "completed",
      };
      const res = await getGrants(data, debouncedSearchText);
      setloading(false);
      if (res.data.totalPages === page) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }

      if (page === 1) {
        setDisableBack(true);
      } else {
        setDisableBack(false);
      }

      if (page === 1 && endPage === 1) {
        setDisableNext(true);
        setDisableBack(true);
      }

      if (res.data.totalPages > page) {
        setDisableNext(false);
      }
      setEndPage(res.data.totalPages);
      if (res.success) {
        let tempGrants: Array<grantRow> = [];
        tempGrants = res.data.grants.map((grant: any, index: number) => {
          return { ...grant, id: index + 1 + (res.data.currentPage - 1) * 8 };
        });
        setGrants([...tempGrants]);
      } else {
        setGrants([]);
      }
    }, 100);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      searchGrants();
    } else {
      fetchAllGrants();
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    if (endPage && endPage > page) {
      setDisableNext(false);
    }
  }, [endPage]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (debouncedSearchText.trim().length > 0) {
      searchGrants();
    } else {
      fetchAllGrants();
    }
  }, [debouncedSearchText]);

  return (
    <>
      <Box>
        <Box sx={SearchRow}>
          <Typography variant="h5" align="left" mb={2}>
            Completed Grants
          </Typography>

          <FormControl variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Search Grant"
              inputProps={{
                type: "search",
              }}
              onChange={handleSearchText}
            />
          </FormControl>
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={grants}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CompletedGrants;
