import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Logo from "../../../assets/images/logo.png";
import UserNav from "./UserNav";
const drawerWidth = 240;

interface dashboardTopNavProps {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileOpen: boolean;
}

function DashboardTopNav({ setMobileOpen, mobileOpen }: dashboardTopNavProps) {
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        boxShadow: 0,
        borderBottom: "1px solid #d0d5d5",
      }}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { md: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <img src={Logo} alt="logo" />
        <UserNav />
      </Toolbar>
    </AppBar>
  );
}

export default DashboardTopNav;
