import React from "react";
import FundingCategories from "./FundingCategories";
import CampaignCategories from "./CampaignCategory";

function Categories() {
  return (
    <>
      <FundingCategories />
      <CampaignCategories />
    </>
  );
}

export default Categories;
