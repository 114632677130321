import {
  Box,
  Typography,
  Stack,
  Button,
  Grid,
  FormControl,
  TextField,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveGrant,
  getGrantDataById,
} from "../../../../requests/authRequest";
import { useAppDispatch } from "../../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { Link } from "react-router-dom";
import { setRejectGrantModal } from "../../../../store/slices/ModalSlice";
import { useWallet } from "@txnlab/use-wallet";
import { client } from "../../../../algorand";
import algosdk from "algosdk";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import algo from "../../../../assets/images/algo.png";
import { approveGrantBlockchain } from "../../../../blockchain";
import fileIcon from "../../../../assets/images/fileIcon.png";
import Wallets from "../../../../algorandWallet";
import YouTube from "react-youtube";

const URL = process.env.REACT_APP_URL;
var getYouTubeID = require("get-youtube-id");
interface grant {
  youTubeLink: any;
  goals: [
    {
      category: string;
      goalName: string;
      goalId: any;
    }
  ];
  images: [];
  grantType: string;
  title: string;
  duration: string;
  shortDescription: string;
  longDescription: string;
  preferredLocation: string;
  minimumGrant: number;
  maximumGrant: number;
  totalGrants: number;
  totalBudget: string;
  target: string;
  video: string;
  videoUrl: string;
  endDate: string;
  status: string;
  createdBy: {
    country: string;
    name: string;
    userId: string;
  };
  grantAppId: number;
  createdAt: string;
  updatedAt: string;
  grantId: string;
  rating?: string;
  name?: string;
  document?: string;
}

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ViewGrant() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [grantInfo, setGrantInfo] = useState<grant>();
  const [rating, setRating] = useState<string>(grantInfo?.rating || "");
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const { grantId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getGrantInfo = async () => {
    if (grantId) {
      dispatch(toggleAppLoading(true));
      const res: any = await getGrantDataById(grantId);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        setGrantInfo(res.data.grant);
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
        navigate(-1);
      }
    } else {
      const toasterData = {
        active: true,
        text: "Trying to access info of invalid user.",
        success: false,
      };
      dispatch(setToaster(toasterData));
      navigate(-1);
    }
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleAcceptGrantBlockchain = async () => {
    const info = {
      wallet_address: activeAccount?.address,
      grant_app_id: grantInfo?.grantAppId,
      review: 1,
    };
    const unsignedTxn = await approveGrantBlockchain(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: "Something went wrong, please try again later.",
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));

      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);

      dispatch(toggleAppLoading(true));

      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      // Wait for confirmation
      dispatch(setAppLoadingText("waiting for transaction confirmation"));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      // get the transaction res
      dispatch(setAppLoadingText("fetching transaction data"));
      let transactionResponse = await client
        .pendingTransactionInformation(transationRes.txId)
        .do();
      dispatch(setAppLoadingText(""));

      return {
        success: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  const [invalidRating, setInvalidRating] = useState<boolean>(false);
  const [errorRating, setErrorRating] = useState<string>("");

  const handleAcceptGrant = async () => {
    let validity = true;
    if (grantInfo?.grantType === "standard" && !rating.trim().length) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter the Rating");
      return;
    } else if (
      (grantInfo?.grantType === "standard" && Number(rating.trim()) < 1) ||
      Number(rating.trim()) > 100
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter valid Rating");
      return;
    } else if (
      grantInfo?.grantType === "standard" &&
      !Number.isInteger(Number(rating.trim()))
    ) {
      validity = false;
      setInvalidRating(true);
      setErrorRating("Please enter only non-decimal values");
      return;
    } else {
      setInvalidRating(false);
      setErrorRating("");
    }
    if (grantId) {
      dispatch(toggleAppLoading(true));

      const blockchainRes = await handleAcceptGrantBlockchain();
      if (!blockchainRes.success) {
        return;
      }
      const info = {
        grantId,
        rating,
      };
      const res = await approveGrant(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: true,
        };
        await getGrantInfo();
        dispatch(setToaster(toasterData));
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    }
  };
  const handleRejectGrant = async () => {
    if (grantId) {
      dispatch(
        setRejectGrantModal({
          grantId,
          rejectGrant: true,
          blockGrant: false,
          rejectGrantAppId: grantInfo?.grantAppId,
        })
      );
    }
  };

  const handleBlockGrant = async () => {
    if (grantId) {
      dispatch(
        setRejectGrantModal({
          grantId,
          rejectGrant: false,
          blockGrant: true,
          rejectGrantAppId: grantInfo?.grantAppId,
        })
      );
    }
  };

  useEffect(() => {
    getGrantInfo();
  }, [grantId]);

  return (
    <>
      {grantInfo ? (
        <Box>
          <Typography variant="h4" align="left" mb={4} fontWeight={600}>
            View Grant Info
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Title
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.title}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Short Description
                  </Typography>
                  <Typography
                    variant="h6"
                    align="justify"
                    style={{ whiteSpace: "pre-line", textAlign: "justify" }}
                  >
                    {grantInfo.shortDescription}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Long Description
                  </Typography>
                  <Typography
                    variant="h6"
                    align="justify"
                    style={{ whiteSpace: "pre-line", textAlign: "justify" }}
                  >
                    {grantInfo.longDescription}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Status
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.status.charAt(0).toUpperCase() +
                      grantInfo.status.slice(1)}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    End Date
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(grantInfo.endDate).format("MMM DD YYYY")}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Country
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.preferredLocation.charAt(0).toUpperCase() +
                      grantInfo.preferredLocation.slice(1)}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Category
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.grantType}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Total Budget
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.totalBudget}
                    <img
                      src={algo}
                      style={{ height: "15px", width: "15px" }}
                      alt=""
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Duration
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.duration} Months
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Minimum Grant
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.minimumGrant}
                    <img
                      src={algo}
                      style={{ height: "15px", width: "15px" }}
                      alt=""
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Maximum Grant
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.maximumGrant}
                    <img
                      src={algo}
                      style={{ height: "15px", width: "15px" }}
                      alt=""
                    ></img>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Total Grants
                  </Typography>
                  <Typography variant="h6" align="left">
                    {grantInfo.totalGrants}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Creator
                  </Typography>
                  <Link
                    to={`/dashboard/user/${grantInfo?.createdBy}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {grantInfo?.name}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1} textAlign="left">
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Supporting document (Optional)
                  </Typography>
                  {grantInfo.document ? (
                    <a
                      href={`${URL}/api/file/${grantInfo.document}`}
                      target="_blank"
                    >
                      <img
                        style={{
                          width: "16px",
                          // marginBottom: "-3px",
                        }}
                        src={fileIcon}
                      ></img>
                    </a>
                  ) : (
                    "No Document Uploaded"
                  )}
                </Box>
                {grantInfo.grantType === "standard" ? (
                  <Box mb={1}>
                    <Typography variant="h5" fontWeight={"600"} align="left">
                      Goals
                    </Typography>
                    {grantInfo.goals?.filter(
                      (el) => el?.category === "environmental"
                    ).length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        Environmental
                      </Typography>
                    )}

                    <Grid container spacing={2}>
                      {grantInfo.goals
                        ?.filter((el) => el?.category === "environmental")
                        ?.map((item: any) => (
                          <Grid xs={4} pl={2} key={item?.goalName}>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked
                                    disabled
                                    name="Goal"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {item?.goalName}
                                  </Typography>
                                }
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>

                    {grantInfo.goals?.filter((el) => el?.category === "social")
                      .length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        Social
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      {grantInfo.goals
                        ?.filter((el) => el?.category === "social")
                        ?.map((item: any) => (
                          <Grid xs={4} pl={2} key={item?.goalName}>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked
                                    disabled
                                    name="Goal"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {item?.goalName}
                                  </Typography>
                                }
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                    {grantInfo.goals?.filter(
                      (el) => el?.category === "governance"
                    ).length > 0 && (
                      <Typography variant="h5" align="left" mt={3} mb={3}>
                        Governance
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      {grantInfo.goals
                        ?.filter((el) => el?.category === "governance")
                        ?.map((item: any) => (
                          <Grid xs={4} pl={2} key={item?.goalName}>
                            <Box>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked
                                    disabled
                                    name="Goal"
                                    color="primary"
                                  />
                                }
                                label={
                                  <Typography
                                    variant="body1"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {item?.goalName}
                                  </Typography>
                                }
                              />
                            </Box>
                          </Grid>
                        ))}
                    </Grid>
                  </Box>
                ) : (
                  " "
                )}
              </Stack>
            </Grid>
            <Grid item lg={8} md={6}>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                  mb: 2,
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  Image
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xl={10} lg={10}>
                    <Carousel
                      swipeable={true}
                      draggable={false}
                      showDots={grantInfo?.images?.length > 1 ? true : false}
                      responsive={responsive}
                      ssr={false} // means to render carousel on server-side.
                      infinite={false}
                      autoPlay={false}
                      autoPlaySpeed={1000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container preview-grant"
                      removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {grantInfo?.images?.map((item: any) => (
                        <Box
                          sx={{
                            backgroundImage: `url('${URL}/api/file/image/${item}')`,
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            width: "500px",
                            height: "500px",
                            cursor: "pointer",
                          }}
                          id={
                            item.length > 0
                              ? `${URL}/api/file/image/${item}`
                              : ""
                          }
                        />
                      ))}
                    </Carousel>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  Video (Optional)
                </Typography>
                <Typography variant="h6" align="left">
                  <video
                    style={
                      grantInfo.video
                        ? { maxWidth: "98%" }
                        : { display: "none" }
                    }
                    src={`${URL}/api/file/video/${grantInfo.video}`}
                    controls
                    width={"50%"}
                    height={"auto"}
                    autoPlay={false}
                  />
                </Typography>
              </Box>
              <Box
                sx={{
                  border: "1px solid #cecbcb",
                  padding: "20px",
                  width: "100%",
                  borderRadius: "4px",
                }}
              >
                <Typography variant="h5" fontWeight={"600"} align="left" mb={1}>
                  YouTube Video (Optional)
                </Typography>
                <Typography variant="h6" align="left">
                  {grantInfo.youTubeLink &&
                    (() => {
                      const id = getYouTubeID(grantInfo.youTubeLink);
                      return (
                        <YouTube
                          videoId={id}
                          opts={{
                            height: "300",
                            width: "51%",
                            playerVars: {
                              autoplay: 1,
                            },
                          }}
                          style={
                            grantInfo.youTubeLink
                              ? { maxWidth: "98%", height: "auto" }
                              : { display: "none" }
                          }
                        />
                      );
                    })()}
                </Typography>
              </Box>
            </Grid>

            {grantInfo && grantInfo.goals.length > 0 && (
              <Grid container spacing={2} style={{ paddingLeft: "15px" }}>
                <Grid item lg={12} md={12}>
                  <Box sx={{ mt: 2, mb: 3 }} style={{ textAlign: "left" }}>
                    {grantInfo?.status === "rejected" ||
                    grantInfo?.status === "blocked" ? null : (
                      <Box>
                        <Typography
                          variant="h4"
                          align="left"
                          fontWeight={600}
                          marginTop={5}
                        >
                          Cashdillo Grant Rating
                        </Typography>
                        <p>Enter the right rating for this grant (1-100)</p>
                        <FormControl sx={{ mt: 0, minWidth: 300 }}>
                          <TextField
                            id="outlined-basic"
                            label="Rating"
                            variant="outlined"
                            disabled={grantInfo.status !== "pending"}
                            sx={{ mt: 2, mb: 2, width: "100%" }}
                            value={rating || grantInfo.rating}
                            onChange={(e) => {
                              setRating(e.target.value);
                              setInvalidRating(false);
                            }}
                            error={invalidRating}
                            helperText={invalidRating && errorRating}
                          />
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Box sx={{ textAlign: "left" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "primary.buttongrey",
                    mt: 4,
                    mr: 2,
                    "&:hover": {
                      backgroundColor: "primary.buttongreydark",
                    },
                    "&:focus": {
                      backgroundColor: "primary.buttongreydark",
                    },
                  }}
                  onClick={handleGoBack}
                >
                  Go Back
                </Button>
                {grantInfo.status === "active" && (
                  <Button
                    variant="contained"
                    color={"error"}
                    sx={{ mt: 4 }}
                    onClick={handleBlockGrant}
                  >
                    Block Grant
                  </Button>
                )}
                {grantInfo?.status === "pending" && (
                  <>
                    <>
                      {new Date().setHours(0, 0, 0, 0) >
                      new Date(grantInfo.endDate).setHours(0, 0, 0, 0) ? (
                        <Tooltip title="Grant end date is expired now">
                          <Button
                            variant="contained"
                            color={"primary"}
                            sx={{
                              backgroundColor: "primary.buttongrey",
                              mt: 4,
                              mr: 2,
                              "&:hover": {
                                backgroundColor: "primary.buttongreydark",
                              },
                              "&:focus": {
                                backgroundColor: "primary.buttongreydark",
                              },
                            }}
                          >
                            Approve Grant
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          disabled={
                            new Date().setHours(0, 0, 0, 0) >
                            new Date(grantInfo.endDate).setHours(0, 0, 0, 0)
                          }
                          variant="contained"
                          color={"primary"}
                          sx={{ mt: 4, mr: 2 }}
                          onClick={handleShowWalletOptions}
                        >
                          Approve Grant
                        </Button>
                      )}
                      {showWalletButtons && (
                        <Wallets
                          open={showWalletButtons}
                          handleClose={() => setShowWalletButtons(false)}
                          handleSubmit={handleAcceptGrant}
                        />
                      )}
                    </>
                    <Button
                      variant="contained"
                      color={"error"}
                      sx={{ mt: 4 }}
                      onClick={handleRejectGrant}
                    >
                      Reject Grant
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewGrant;
