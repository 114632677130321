import React, { useState } from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import {
  ContentStyle,
  modalContainer,
  modalWrapper,
  textArea,
  titleStyle,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../store";
import { setRejectMilestoneModal } from "../../store/slices/ModalSlice";
import { useWallet } from "@txnlab/use-wallet";
import { client } from "../../algorand";
import { rejectMilestoneBlockchain } from "../../blockchain";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import algosdk from "algosdk";
import { rejectMilestone } from "../../requests/authRequest";

import Wallets from "../../algorandWallet";
function RejectMilestoneModal() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const open = useAppSelector((state) => state.modalReducer.rejectMilestone);
  const milestonAppId = useAppSelector(
    (state) => state.modalReducer.rejectMilestoneAppId
  );
  const campaignAppId = useAppSelector(
    (state) => state.modalReducer.rejectCampaignAppId
  );
  const milestoneNumber = useAppSelector(
    (state) => state.modalReducer.rejectMilestoneNumber
  );
  const [reason, setReason] = useState<string>("");
  const [invalidReason, setInvalidReason] = useState<boolean>(false);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setReason("");
    setInvalidReason(false);
    const payload = {
      rejectMilestone: false,
      rejectMilestoneAppId: undefined,
      rejectCampaignAppId: undefined,
      rejectMilestoneNumber: undefined,
    };
    dispatch(setRejectMilestoneModal(payload));
  };

  const validateReason = () => {
    let res = true;
    if (reason.trim().length <= 0 || reason.trim().length > 3000) {
      res = false;
      setInvalidReason(true);
    } else {
      setInvalidReason(false);
    }
    return res;
  };

  const handleReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleRejectMilestoneBlockchain = async () => {
    dispatch(toggleAppLoading(true));
    const info = {
      admin_wallet_address: activeAccount?.address,
      milestone_app_id: milestonAppId, // milestone app id of the milestone that has to be ended
      campaign_app_id: campaignAppId,
      milestone_number: milestoneNumber,
      note: `Reject, ${reason}`,
    };

    const unsignedTxn = await rejectMilestoneBlockchain(info);

    if (!unsignedTxn.success) {
      const toastPaylaod = {
        text: "Something went wrong, please try again later.",
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }

    try {
      dispatch(setAppLoadingText("signing transaction"));
      dispatch(toggleAppLoading(false));
      const transactionArr = unsignedTxn.data.map((data: any) => {
        return {
          txn: algosdk.decodeUnsignedTransaction(
            Buffer.from(data.txn, "base64")
          ),
        };
      });
      const currentProvider = providers?.find((wallet: any) => wallet.isActive);
      await currentProvider?.reconnect();
      const encodedTxns = transactionArr.map((txn: any) => {
        return algosdk.encodeUnsignedTransaction(txn.txn);
      });
      const signedTxn = await signTransactions(encodedTxns);
      dispatch(toggleAppLoading(true));
      // Submit the transaction
      const transationRes = await client
        .sendRawTransaction(
          signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
        )
        .do();

      // Wait for confirmation
      dispatch(setAppLoadingText("waiting for transaction confirmation"));
      let confirmedTxn = await algosdk.waitForConfirmation(
        client,
        transationRes.txId,
        4
      );

      return {
        succcess: true,
      };
    } catch (e: any) {
      const toastPaylaod = {
        text: e.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(setAppLoadingText(""));
      dispatch(toggleAppLoading(false));
      return {
        success: false,
      };
    }
  };

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  const handleRejectAction = async () => {
    const valid = validateReason();

    let milestoneId = window.location.pathname.split("/");
    if (
      !valid ||
      milestonAppId === 0 ||
      milestonAppId === undefined ||
      milestoneId.length < 4 ||
      milestoneId[3].length < 2
    ) {
      return;
    }

    // dispatch(toggleAppLoading(true));
    const res = await handleRejectMilestoneBlockchain();
    if (res.succcess) {
      const info = {
        milestoneId: milestoneId[3],
        comment: reason,
      };
      const res2 = await rejectMilestone(info);
      if (res2.success) {
        dispatch(toggleAppLoading(false));
        dispatch(setAppLoadingText(""));
        const toastPaylaod = {
          text: "Milestone rejected successfully",
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        handleClose();
        window.location.reload();
      } else {
        dispatch(toggleAppLoading(false));
        dispatch(setAppLoadingText(""));
        const toastPaylaod = {
          text: res2.data.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box onClick={(e) => e.stopPropagation()} sx={modalContainer}>
          <Box sx={titleStyle}>
            <Typography variant="h4" align="center" mb={2}>
              Reject Milestone
            </Typography>
          </Box>
          <Box sx={ContentStyle}>
            <TextField
              fullWidth
              hiddenLabel
              multiline
              minRows={2}
              type={"text"}
              placeholder="Reason to reject"
              variant="outlined"
              onChange={handleReasonChange}
              error={invalidReason}
              sx={textArea}
              helperText={
                invalidReason && "Please enter a reason for your action"
              }
            />

            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={3}
              mb={3}
            >
              <>
                <Button
                  onClick={handleShowWalletOptions}
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
                {showWalletButtons && (
                  <Wallets
                    open={showWalletButtons}
                    handleClose={() => setShowWalletButtons(false)}
                    handleSubmit={handleRejectAction}
                  />
                )}
              </>
              <Button onClick={handleClose} variant="contained" color="error">
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default RejectMilestoneModal;
