export const CampaigngForm = {
  marginTop: "-24px",
  paddingBottom: "20px",
  "& .inputfile": {
    display: "none",
  },
  "& .uploadButton": {
    color: "#000",
    fontFamily: "TTNormsRegular",
    marginTop: 1,
    paddingLeft: 0,
  },
  "& .ck-editor__editable_inline": {
    minHeight: "260px",
    maxHeight: "260px",
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
    // marginBottom: 3,
    backgroundColor: "white.main",
    "& .MuiOutlinedInput-input": {
      fontSize: "16px",
      fontFamily: "TTNormsMedium",
      borderRadius: "6px",
      textAlign: "left",
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white.main",
    },
  },
  "& .MuiFormLabel-root": {
    textAlign: "left",
    marginBottom: 2,
  },
};
