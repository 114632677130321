import { Box, Button, Stack, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  approveUser,
  blockUser,
  getUserById,
} from "../../../requests/authRequest";
import { useAppDispatch } from "../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { DetailUser } from "./styles";

interface user {
  createdAt: string;
  email: string;
  name: string;
  role: string;
  status: string;
  userId: string;
}

function ViewUser() {
  const [userInfo, setUserInfo] = useState<user>();
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getUserInfo = async () => {
    if (userId) {
      dispatch(toggleAppLoading(true));
      const res: any = await getUserById(userId);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        setUserInfo(res.data);
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
        navigate(-1);
      }
    } else {
      const toasterData = {
        active: true,
        text: "Trying to access info of invalid user.",
        success: false,
      };
      dispatch(setToaster(toasterData));
      navigate(-1);
    }
  };

  const handleUserState = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (
      (userInfo && userInfo.status === "pending") ||
      (userInfo && userInfo.status === "blocked")
    ) {
      dispatch(toggleAppLoading(true));
      const info = {
        userId: userInfo.userId,
      };
      const res = await approveUser(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: true,
        };
        await getUserInfo();
        dispatch(setToaster(toasterData));
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    } else if (userInfo && userInfo.status === "active") {
      dispatch(toggleAppLoading(true));
      const info = {
        userId: userInfo.userId,
      };
      const res = await blockUser(info);
      if (res.success) {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: true,
        };
        await getUserInfo();
        dispatch(setToaster(toasterData));
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
      }
      dispatch(toggleAppLoading(false));
    }
  };

  const handleGoBack = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    navigate(-1);
  };

  useEffect(() => {
    getUserInfo();
  }, [userId]);
  return (
    <>
      {userInfo ? (
        <Box>
          <Typography variant="h4" align="left" mb={4} fontWeight={600}>
            View user info
          </Typography>
          <Stack spacing={2}>
            <Box sx={DetailUser}>
              <div className="label">Name:</div>
              <div className="value">{userInfo.name}</div>
            </Box>
            <Box sx={DetailUser}>
              <div className="label">Email:</div>
              <div className="value">{userInfo.email}</div>
            </Box>
            <Box sx={DetailUser}>
              <div className="label">Role</div>
              <div className="value">{userInfo.role}</div>
            </Box>
            <Box sx={DetailUser}>
              <div className="label">Status:</div>
              <div className="value">{userInfo.status}</div>
            </Box>
            <Box sx={DetailUser}>
              <div className="label">Member Since:</div>
              <div className="value">
                {moment(userInfo.createdAt).format("MMMM Do YYYY")}
              </div>
            </Box>
            <Box sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "primary.buttongrey",
                  mt: 4,
                  mr: 2,
                  "&:hover": {
                    backgroundColor: "primary.buttongreydark",
                  },
                  "&:focus": {
                    backgroundColor: "primary.buttongreydark",
                  },
                }}
                onClick={handleGoBack}
              >
                Go Back
              </Button>
              <Button
                variant="contained"
                color={
                  userInfo.status === "pending"
                    ? "warning"
                    : userInfo.status === "active"
                    ? "error"
                    : "primary"
                }
                sx={{ mt: 4 }}
                onClick={handleUserState}
              >
                {userInfo.status === "pending"
                  ? "Approve User"
                  : userInfo.status === "active"
                  ? "Block User"
                  : "Unblock User"}
              </Button>
            </Box>
          </Stack>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default ViewUser;
