import { axiosInstance } from "./AuthRequestInterceptor";
const URL = process.env.REACT_APP_URL;

export const logoutUser = async () => {
  var config = {
    method: "post",
    url: `${URL}/api/auth/logout`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getAllUser = async (data, searchText) => {
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/users?page=${data.page}&limit=${data.limit}&searchText=${searchText}`
        : `${URL}/api/admin/users?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getFundingCategories = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/funding-categories?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getCampaignCategories = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/categories?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const deleteFundingCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "delete",
    url: `${URL}/api/admin/funding-category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const deleteCampaignCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "delete",
    url: `${URL}/api/admin/category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const createCampaignCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const createFundingCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/funding-category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const editFundingCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "put",
    url: `${URL}/api/admin/funding-category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const editCampaignCategory = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "put",
    url: `${URL}/api/admin/category`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getDashboardCount = async () => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/dashboard-count`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getAllCampaign = async (data, searchText) => {
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/campaigns?page=${data.page}&limit=${data.limit}&searchText=${searchText}`
        : `${URL}/api/admin/campaigns?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getUserById = async (id) => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/user?userId=${id}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const blockUser = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/block-user`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveUser = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/approve-user`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getCampaignById = async (campaignId) => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/campaign/edit?campaignId=${campaignId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveCampaign = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/approve-campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const rejectCampaign = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/reject-campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};
export const blockCampaign = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/block-campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const mintNft = async (info) => {
  var data = new FormData();
  data.append("media", info.file);
  data.append("title", info.title);
  data.append("price", info.price);
  data.append("description", info.description);

  var config = {
    method: "post",
    url: `${URL}/api/reward/nft`,
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getAllNftByAdmin = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/reward/all-admin-nfts?page=${info.page}&limit=${info.limit}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getNftDataById = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/api/reward/nft-by-id?nftId=${info.nftId}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getSubmittedMilestones = async (data, searchText) => {
  // milestones?page=1&limit=10&status=in-progress
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=report-submitted&searchText=${searchText}`
        : `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=report-submitted`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getRejectedMilestones = async (data, searchText) => {
  // milestones?page=1&limit=10&status=in-progress
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=rejected&searchText=${searchText}`
        : `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=rejected`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getApprovedMilestones = async (data, searchText) => {
  // milestones?page=1&limit=10&status=in-progress
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=completed&searchText=${searchText}`
        : `${URL}/api/admin/milestones?page=${data.page}&limit=${data.limit}&status=completed`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getMilestoneDataById = async (info) => {
  const config = {
    method: "get",
    url: `${URL}/api/campaign/milestone?milestoneId=${info.milestoneId}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveMilestone = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/admin/approve-milestone-report`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const rejectMilestone = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/admin/reject-milestone-report`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getAllGoals = async (data, searchText = "") => {
  const search =
    searchText && searchText.trim().length ? `&searchText=${searchText}` : ``;
  var config = {
    method: "get",
    url: `${URL}/api/admin/goal?page=${data.page}&limit=${data.limit}` + search,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error in data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const createGoal = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "post",
    url: `${URL}/api/admin/goal`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const UpdateGoal = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "put",
    url: `${URL}/api/admin/goal`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const deleteGoal = async (info) => {
  const data = JSON.stringify(info);
  var config = {
    method: "delete",
    url: `${URL}/api/admin/goal`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getGrants = async (data, searchText) => {
  var config = {
    method: "get",
    url:
      searchText && searchText.trim().length > 0
        ? `${URL}/api/admin/grants?page=${data.page}&limit=${data.limit}&status=${data.status}&searchText=${searchText}`
        : `${URL}/api/admin/grants?page=${data.page}&limit=${data.limit}&status=${data.status}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getDonations = async (data) => {
  var config = {
    method: "get",
    url: `${URL}/api/admin/campaigns-collected-amount?page=${data.page}&limit=${data.limit}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};
export const getDonationById = async (donationId) => {
  var config = {
    method: "get",

    url: `${URL}/api/admin/campaigns-collected-amount?donationId=${donationId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getGrantDataById = async (info) => {
  const config = {
    method: "get",
    url: `${URL}/api/admin/grant?grantId=${info}`,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveGrant = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/admin/approve-grant`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const rejectGrant = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/admin/reject-grant`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const blockGrant = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/admin/block-grant`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getAllTemplates = async () => {
  // const search =
  //   searchText && searchText.trim().length ? `&searchText=${searchText}` : ``;
  var config = {
    method: "get",
    url: `${URL}/api/template/templates`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error in data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getTemplateData = async (templateId) => {
  var config = {
    method: "get",
    url: `${URL}/api/template/?templateId=${templateId}`,
  };
  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error in data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const updateTemplate = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/template/update-template`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const sendEmailData = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/email/sendEmail`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axiosInstance(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error sending email" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};
