import React from "react";
import DashboardSideNav from "./DashboardSideNav";
import DashboardTopNav from "./DashboardTopNav";

interface dashboardNavProps {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
  mobileOpen: boolean;
}

function DashboardNav({ setMobileOpen, mobileOpen }: dashboardNavProps) {
  return (
    <>
      <DashboardTopNav setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />
      <DashboardSideNav setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />
    </>
  );
}

export default DashboardNav;
