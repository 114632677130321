import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import {
  deleteCampaignCategory,
  deleteFundingCategory,
} from "../../requests/authRequest";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
import { setDeleteCategoryModal } from "../../store/slices/ModalSlice";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { modalContainer, modalWrapper, titleStyle } from "./styles";

function DeleteCategoryModal() {
  const open = useAppSelector((state) => state.modalReducer.deleteCategory);
  const dispatch = useAppDispatch();
  const deleteType = useAppSelector((state) => state.modalReducer.deleteType);
  const categoryId = useAppSelector(
    (state) => state.modalReducer.deleteCategoryId
  );

  const handleClose = () => {
    const payload = {
      deleteCategory: false,
      deleteCategoryId: "",
      deleteType: "",
    };
    dispatch(setDeleteCategoryModal(payload));
  };

  const handleDelete = async () => {
    dispatch(toggleAppLoading(true));
    if (
      deleteType.length > 0 &&
      deleteType === "campaign" &&
      categoryId.length > 0
    ) {
      const data = {
        categoryId: categoryId,
      };
      const res = await deleteCampaignCategory(data);
      if (res.success) {
        const toastPayload = {
          active: true,
          text: "Category deleted successfully",
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    } else if (
      deleteType.length > 0 &&
      deleteType === "funding" &&
      categoryId.length > 0
    ) {
      const data = {
        fundingCategoryId: categoryId,
      };
      const res = await deleteFundingCategory(data);
      if (res.success) {
        const toastPayload = {
          active: true,
          text: "Category deleted successfully",
          success: true,
        };

        dispatch(setToaster(toastPayload));
      } else {
        const toastPayload = {
          active: true,
          text: res.data.message,
          success: false,
        };

        dispatch(setToaster(toastPayload));
      }
    }
    dispatch(toggleAppLoading(false));
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box onClick={handleClose} sx={modalWrapper}>
        <Box
          onClick={(e) => e.stopPropagation()}
          sx={modalContainer}
          style={{ maxWidth: "320px" }}
        >
          <Box sx={{ textAlign: "center" }}>
            <DangerousIcon sx={{ fontSize: "50px" }} color="error" />
          </Box>
          <Box sx={titleStyle} mb={3} mt={2}>
            <Typography variant="h6" align="center" mb={0} fontWeight={600}>
              Are you sure you want to delete this category ?
            </Typography>
          </Box>
          <Box>
            <Box
              sx={{
                textAlign: "center",
                justifyContent: "space-around",
                display: "flex",
              }}
              mt={2}
            >
              <Button onClick={handleDelete} variant="contained" color="error">
                Delete
              </Button>
              <Button
                onClick={handleClose}
                variant="contained"
                color="inherit"
                sx={{
                  backgroundColor: "primary.grey",
                }}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

export default DeleteCategoryModal;
