import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const colors = {
  primary: {
    main: "#61f359",
    background: "#f1f3f4",
    buttongrey: "#e3e5e5",
    buttongreydark: "#d0d5d5",
    red: "#f35959",
    grey: "#b9b8b8",
  },
  secondary: {
    main: "#19857b",
  },
  black: {
    main: "#000000",
  },
  green: {
    main: "#61f359",
    dark: "#55d54e",
  },
  white: {
    main: "#ffffff",
  },
  red: {
    main: "#f35959",
  },
};

// A custom theme for this app
const theme = createTheme({
  palette: colors,
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: "4px",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
      variants: [
        {
          props: { variant: "h2" },
          style: {
            fontSize: "50px",
            "@media (max-width:1536px) ": {
              fontSize: "40px",
            },
            "@media (max-width:1200px) ": {
              fontSize: "35px",
            },
            "@media (max-width:992px) ": {
              fontSize: "31px",
            },
          },
        },
        {
          props: { variant: "h3" },
          style: {
            fontSize: "30px",
            "@media (max-width:1200px) ": {
              fontSize: "22px",
            },
            "@media (max-width:992px) ": {
              fontSize: "20px",
            },
          },
        },
        {
          props: { variant: "h4" },
          style: {
            fontSize: "26px",
            "@media (max-width:1200px) ": {
              fontSize: "20px",
            },
            "@media (max-width:992px) ": {
              fontSize: "18px",
            },
          },
        },
        {
          props: { variant: "h5" },
          style: {
            fontSize: "20px",
            "@media (max-width:1200px) ": {
              fontSize: "18px",
            },
            "@media (max-width:992px) ": {
              fontSize: "16px",
            },
          },
        },
        {
          props: { variant: "h6" },
          style: {
            fontSize: "16px",
            "@media (max-width:992px) ": {
              fontSize: "14px",
            },
          },
        },
        {
          props: { variant: "body2" },
          style: {
            fontSize: "12px",
            "@media (max-width:600px) ": {
              fontSize: "10px",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          border: "0px",
          height: "44px",
          padding: "12px 24px",
          fontSize: "14px",
          fontWeight: "600",
          textTransform: "capitalize",
          boxShadow: "none",
          color: "black.main",
          "&.round-button": {
            borderRadius: "20px",
            padding: "12px 30px",
          },
          "&.darkButton": {
            backgroundColor: colors.black.main,
            color: colors.primary.main,
            "&:hover": {
              backgroundColor: "#1c1c1c",
              color: colors.primary.main,
            },
            "&:focus": {
              backgroundColor: "#1c1c1c",
              color: colors.primary.main,
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            border: `none`,
            backgroundColor: colors.primary.main,
            width: "fit-content",
            color: "#000",
            "&:hover": {
              backgroundColor: "#50c749",
              color: "#000",
              boxShadow: "none",
            },
            "&:focus": {
              backgroundColor: "#50c749",
              color: "#000",
              boxShadow: "none",
            },
          },
        },
      ],
    },
  },
});
export default theme;
