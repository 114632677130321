import React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../store";
import { setToaster } from "../store/slices/LoadinAndNotifSlice";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function NotificationToast() {
  const toastInfo = useAppSelector(
    (state) => state.notifAndLoadingReducer.toasterState
  );
  const dispatch = useAppDispatch();

  const handleClose = () => {
    const payload = {
      active: false,
      text: "",
      success: toastInfo.success,
    };
    dispatch(setToaster(payload));
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={toastInfo.active}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {toastInfo.success ? (
          <Alert severity="success">{toastInfo.text}</Alert>
        ) : (
          <Alert severity="error">{toastInfo.text}</Alert>
        )}
      </Snackbar>
    </Stack>
  );
}

export default NotificationToast;
