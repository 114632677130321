import {
  Box,
  FormControl,
  IconButton,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridWrap, footer, SearchRow } from "./styles";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../../store";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { toggleAppLoading } from "../../../../store/slices/LoadinAndNotifSlice";
import { getAllUser } from "../../../../requests/authRequest";
import moment from "moment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/Person";
import WarningIcon from "@mui/icons-material/Warning";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Style } from "@mui/icons-material";
import { useDebounce } from "use-debounce";
import CustomNoRowsOverlay from "../../../table/CustomNoRowOverlay";
interface userRow {
  status: string;
  name: string;
  email: string;
  role: string;
  createdAt: string;
  userId: string;
  id: number;
  action: string;
}

function AllUser() {
  const [user, setUser] = useState<Array<userRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState<string>("");
  const timeout = useRef<any>();
  const [debouncedSearchText] = useDebounce(searchText, 500);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 150,
      editable: true,
      flex: 1,
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Joined At",
      sortable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === "active") {
          return (
            <IconButton color="success">
              <PersonIcon />
            </IconButton>
          );
        } else if (params.row.status === "pending") {
          return (
            <IconButton>
              <WarningIcon color="warning" />
            </IconButton>
          );
        } else {
          return (
            <IconButton>
              <RemoveCircleIcon color="error" />
            </IconButton>
          );
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/dashboard/user/${params.row.userId}`);
        };
        return (
          <Box>
            <IconButton onClick={handleView}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchText(e.target.value);
  };

  const fetchMyUser = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 8,
    };
    const res = await getAllUser(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }

    if (res.data.totalPages > page) {
      setDisableNext(false);
    }
    setEndPage(res.data.totalPages);
    let tempCampaign: Array<userRow> = [];

    res.data.users.map((user: any, index: number) => {
      const payload: userRow = {
        id: index + 1 + (res.data.currentPage - 1) * 8,
        status: user.status,
        name: user.name,
        email: user.email,
        role: user.role,
        createdAt: moment(user.createdAt).format("DD-MMM-YYYY"),
        userId: user.userId,
        action: "string",
      };

      tempCampaign.push(payload);
      return null;
    });

    setUser([...tempCampaign]);
  };

  const searchUser = async () => {
    clearTimeout(timeout.current);

    if (!debouncedSearchText.trim()) {
      return;
    }

    timeout.current = setTimeout(async () => {
      // do everything
      setloading(true);
      const data = {
        page: page,
        limit: 8,
      };
      const res = await getAllUser(data, debouncedSearchText);
      setloading(false);
      if (res.data.totalPages === page) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }

      if (page === 1) {
        setDisableBack(true);
      } else {
        setDisableBack(false);
      }

      if (page === 1 && endPage === 1) {
        setDisableNext(true);
        setDisableBack(true);
      }
      if (res.data.totalPages > page) {
        setDisableNext(false);
      }
      setEndPage(res.data.totalPages);
      let tempCampaign: Array<userRow> = [];

      res.data.users.map((user: any, index: number) => {
        const payload: userRow = {
          id: index + 1 + (res.data.currentPage - 1) * 8,
          status: user.status,
          name: user.name,
          email: user.email,
          role: user.role,
          createdAt: moment(user.createdAt).format("DD-MMM-YYYY"),
          userId: user.userId,
          action: "string",
        };

        tempCampaign.push(payload);
        return null;
      });

      setUser([...tempCampaign]);
    }, 100);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (searchText.trim().length > 0) {
      searchUser();
    } else {
      fetchMyUser();
    }
  }, [page]);

  useEffect(() => {
    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    if (endPage && endPage > page) {
      setDisableNext(false);
    }
  }, [endPage]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  useEffect(() => {
    if (debouncedSearchText.trim().length > 0) {
      searchUser();
    } else {
      fetchMyUser();
    }
  }, [debouncedSearchText]);
  return (
    <>
      <Box>
        <Box sx={SearchRow}>
          <Typography variant="h5" align="left">
            All Users List
          </Typography>

          <FormControl variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Search User"
              inputProps={{
                type: "search",
              }}
              onChange={handleSearchText}
            />
          </FormControl>
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={user}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AllUser;
