/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getNftDataById } from "../../../../requests/authRequest";
import { useAppDispatch } from "../../../../store";
import {
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import {
  nftDataContainer,
  nftSaleInfoContainer,
  UploadBox,
  viewNftContainer,
} from "./styles";
import TransactionHistory from "./TransactionHistory";

interface nft {
  price: number;
  isAdmin: boolean;
  title: string;
  description: string;
  media: string;
  createdBy: string;
  createdAt: string;
  purchasedAt: string;
  purchasedBy: {
    name: string;
    userId: string;
  };
  nftId: string;
}
function ViewNft() {
  const [nftData, setNftData] = useState<nft>();

  const [transactionHistory, setTransactionHistory] = useState<Array<any>>([]);
  const { nftId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const fetchNftData = async () => {
    const info = {
      nftId: nftId,
    };
    dispatch(toggleAppLoading(true));
    const res = await getNftDataById(info);
    dispatch(toggleAppLoading(false));

    if (res.success) {
      setNftData(res.data);
      const transData = [
        {
          event: "Sale",
          price: res.data.price,
          from: "Admin",
          to: res.data.purchasedBy.name,
          date: res.data.purchasedAt,
        },
        {
          event: "Minted",
          price: 0,
          from: "NullAddress",
          to: "Admin",
          date: res.data.createdAt,
        },
      ];

      setTransactionHistory(transData);
    } else {
      const toastPaylaod = {
        active: true,
        success: false,
        text: res.data.message,
      };
      dispatch(setToaster(toastPaylaod));
      navigate(-1);
    }
  };

  useEffect(() => {
    fetchNftData();
  }, [nftId]);
  return (
    <>
      {nftData && (
        <Box sx={viewNftContainer}>
          <Typography variant="h2" mb={3}>
            NFT Info
          </Typography>
          <Box sx={nftDataContainer}>
            <Box sx={UploadBox}>
              <video
                style={{ objectFit: "cover" }}
                width="100%"
                height="100%"
                autoPlay
                muted
                loop
                poster={nftData.media}
              >
                <source src={nftData.media} type="video/mp4" />
                <source src={nftData.media} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            </Box>
            <Typography
              sx={{
                color: "initial",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              variant="h3"
              align="left"
              mb={2}
              className="bold"
              fontWeight={600}
            >
              {nftData.title}
            </Typography>
            <Typography minHeight={"84px"} mb={2}>
              {nftData.description}
            </Typography>
            <Typography>
              {" "}
              <b>Price</b> - {nftData.price} Algo
            </Typography>
          </Box>
          <Box sx={nftSaleInfoContainer}>
            <Typography
              sx={{
                color: "initial",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
              variant="h4"
              align="left"
              mb={1}
              className="bold"
              fontWeight={600}
            >
              Mint Info -
            </Typography>
            <Typography mb={1}>
              <b>Created on</b> -{" "}
              {moment(nftData.createdAt).format("DD MMMM YYYY")}
            </Typography>
            <Typography mb={3}>
              <b>Creator</b> -{" "}
              <Link to={`/dashboard/user/${nftData.createdBy}`}>
                {nftData.createdBy}
              </Link>
            </Typography>
            {transactionHistory.length > 0 && (
              <TransactionHistory transactionData={transactionHistory} />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default ViewNft;
