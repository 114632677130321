import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface authState {
  loggedIn: boolean;
  userToken: string;
  userAppId: string;
  name: string;
}

// Define the initial state using that type
const initialState: authState = {
  loggedIn: false,
  userToken: "",
  userAppId: "",
  name: "",
};

export const userSlice = createSlice({
  name: "UserSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setUserToken: (state, action: PayloadAction<authState>) => {
      state.loggedIn = action.payload.loggedIn;
      state.userToken = action.payload.userToken;
      state.userAppId = action.payload.userAppId;
      state.name = action.payload.name;
    },
  },
});

export const { setUserToken } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.counter.value

export default userSlice.reducer;
