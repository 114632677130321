import { Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataGridWrap, footer } from "./style";
import EditIcon from "@mui/icons-material/Edit";
import { getAllTemplates } from "../../requests/authRequest";
import { useAppDispatch } from "../../store";
import {
  setAppLoadingText,
  toggleAppLoading,
} from "../../store/slices/LoadinAndNotifSlice";
interface templateRow {
  id: number;
  templatesName: string;
  status: string;
  action: string;
  templateId: string;
}
function Templates() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [templates, setTemplates] = useState<Array<templateRow>>([]);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "templatesName",
      headerName: "Templates",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          navigate(`/dashboard/edit-templates/${params.row.templateId}`);
        };
        return (
          <Box>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchAllTemplates = async () => {
    dispatch(setAppLoadingText(""));
    dispatch(toggleAppLoading(true));
    const res = await getAllTemplates();
    dispatch(toggleAppLoading(false));
    if (res.success) {
      let allTemplates: Array<templateRow> = [];

      res.data.map((template: any, index: number) => {
        const payload: templateRow = {
          id: index + 1,
          templatesName: template.name,
          status: template.status,
          action: "action",
          templateId: template._id,
        };

        allTemplates.push(payload);
        return null;
      });

      setTemplates([...allTemplates]);
    }
  };

  useEffect(() => {
    fetchAllTemplates();
  }, []);

  return (
    <Box>
      <Typography variant="h4" align="left" mb={4} fontWeight={600}>
        Email Templates
      </Typography>
      <Box sx={DataGridWrap}>
        <DataGrid
          rows={templates}
          columns={columns}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          hideFooterPagination={true}
          hideFooter={true}
        />
      </Box>
    </Box>
  );
}
export default Templates;
