export const viewNftContainer = {
  textAlign: "left",
};

export const UploadBox = {
  height: "auto",
  width: "98%",
  maxWidth: "500px",
  bgcolor: "white.main",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "top",
  marginBottom: 1,
};

export const nftDataContainer = {
  height: "auto",
  width: "98%",
  maxWidth: "500px",
  marginBottom: "20px",
};
export const nftSaleInfoContainer = {};
