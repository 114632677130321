import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useRef, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import moment from "moment";
import { useAppDispatch } from "../../../store";
import { deleteGoal, getAllGoals } from "../../../requests/authRequest";
import {
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";
import { DataGridWrap, footer } from "./styles";
import CreateGoal from "./createGoal";
import { setCreateGoalModal } from "../../../store/slices/ModalSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { modalContainer, modalWrapper, titleStyle } from "../../modals/styles";
import DangerousIcon from "@mui/icons-material/Dangerous";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "use-debounce";

interface goalRow {
  id: number;
  category: string;
  goalName: string;
  createdOn: string | Date;
  status: string;
  goalId: string;
  action: string;
}

function AllGoals() {
  const [user, setUser] = useState<Array<goalRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const [openDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [goalId, setGoalId] = useState<string>("");
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedSearchText] = useDebounce(searchText, 500);
  const timeout = useRef<any>();

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "category",
      headerName: "Goal Category",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "goalName",
      headerName: "Goal Name",
      minWidth: 200,
      editable: true,
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Created On",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          const payload = {
            createGoal: false,
            editGoal: true,
            category: params.row.category.toLowerCase(),
            goalName: params.row.goalName,
            goalId: params.row.goalId,
          };
          dispatch(setCreateGoalModal(payload));
        };

        const handleDelete = (e: any) => {
          e.stopPropagation();
          setGoalId(params.row.goalId);
          setDeleteModal(true);
        };
        return (
          <Box>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchAllGoals = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 8,
    };
    const res = await getAllGoals(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    if (res.success) {
      let tempGoal: Array<goalRow> = [];

      res.data.goals.map((goal: any, index: number) => {
        const payload: goalRow = {
          id: index + 1 + (res.data.currentPage - 1) * 8,
          category:
            goal.category.charAt(0).toUpperCase() + goal.category.slice(1),
          goalName: goal.goalName,
          createdOn: moment(goal.createdAt).format("DD-MMM-YYYY"),
          status: goal.status ? goal.status : "",
          goalId: goal.goalId,
          action: "action",
        };

        tempGoal.push(payload);
        return null;
      });

      setUser([...tempGoal]);
    }
  };

  const searchAllGoals = () => {
    clearTimeout(timeout.current);

    timeout.current = setTimeout(async () => {
      // do everything
      setloading(true);
      const data = {
        page: page,
        limit: 8,
      };
      const res = await getAllGoals(data, debouncedSearchText);
      setloading(false);
      if (res.data.totalPages === page) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }

      if (page === 1) {
        setDisableBack(true);
      } else {
        setDisableBack(false);
      }

      if (page === 1 && endPage === 1) {
        setDisableNext(true);
        setDisableBack(true);
      }

      if (res.data.totalPages > page) {
        setDisableNext(false);
      }
      setEndPage(res.data.totalPages);
      if (res.success) {
        let tempGoal: Array<goalRow> = [];

        res.data.goals.map((goal: any, index: number) => {
          const payload: goalRow = {
            id: index + 1 + (res.data.currentPage - 1) * 8,
            category:
              goal.category.charAt(0).toUpperCase() + goal.category.slice(1),
            goalName: goal.goalName,
            createdOn: moment(goal.createdAt).format("DD-MMM-YYYY"),
            status: goal.status ? goal.status : "",
            goalId: goal.goalId,
            action: "action",
          };

          tempGoal.push(payload);
          return null;
        });

        setUser([...tempGoal]);
      } else {
        setUser([]);
      }
    }, 100);
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleOpenCreateGoal = () => {
    const payload = {
      createGoal: true,
      editGoal: false,
      category: "",
      goalName: "",
      goalId: "",
    };
    dispatch(setCreateGoalModal(payload));
  };

  const handleDelete = async () => {
    dispatch(toggleAppLoading(true));
    const res = await deleteGoal({ goalId: goalId });
    dispatch(toggleAppLoading(true));
    if (res.success) {
      fetchAllGoals();
      setDeleteModal(false);
      setGoalId("");
      const toasterData = {
        active: true,
        text: "Goal deleted successfully",
        success: true,
      };
      dispatch(setToaster(toasterData));
    }
  };

  const handleClose = async () => {
    setDeleteModal(false);
    setGoalId("");
  };

  const handleSearchText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (debouncedSearchText.trim().length > 0) {
      searchAllGoals();
    } else {
      fetchAllGoals();
    }
  }, [debouncedSearchText]);

  useEffect(() => {
    if (searchText.trim().length > 0) {
      searchAllGoals();
    } else {
      fetchAllGoals();
    }
  }, [page]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  return (
    <Box>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Typography
            style={{ textAlign: "left", fontSize: "2rem" }}
            variant="h3"
          >
            All SDG Goals
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{ mb: 2, display: "flex", justifyContent: "end" }}
        >
          <FormControl variant="outlined">
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder="Search Campaign"
              inputProps={{
                type: "search",
              }}
              onChange={handleSearchText}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={2}
          sx={{ mb: 2, display: "flex", justifyContent: "end" }}
        >
          <Button
            onClick={() => {
              handleOpenCreateGoal();
            }}
            variant="contained"
            color="primary"
            className="round-button"
          >
            Create New Goal
          </Button>
        </Grid>
      </Grid>
      <Box sx={DataGridWrap}>
        <DataGrid
          rows={user}
          columns={columns}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          hideFooterPagination={true}
          hideFooter={true}
        />
        <Box sx={footer}>
          <p>
            {page} - {endPage}
          </p>
          <IconButton onClick={handleBack} disabled={disableBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton onClick={handleNext} disabled={disableNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
      <CreateGoal
        hendleFetchGoals={() => {
          fetchAllGoals();
        }}
      />
      <Modal
        open={openDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalWrapper}>
          <Box sx={modalContainer} style={{ maxWidth: "320px" }}>
            <Box sx={{ textAlign: "center" }}>
              <DangerousIcon sx={{ fontSize: "50px" }} color="error" />
            </Box>
            <Box sx={titleStyle} mb={3} mt={2}>
              <Typography variant="h6" align="center" mb={0} fontWeight={600}>
                Are you sure you want to delete this category ?
              </Typography>
            </Box>
            <Box>
              <Box
                sx={{
                  textAlign: "center",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                mt={2}
              >
                <Button
                  onClick={handleDelete}
                  variant="contained"
                  color="error"
                >
                  Delete
                </Button>
                <Button
                  onClick={handleClose}
                  variant="contained"
                  color="inherit"
                  sx={{
                    backgroundColor: "primary.grey",
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AllGoals;
