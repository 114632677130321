import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
  Box,
} from "@mui/material";
import NavLogo from "../../../assets/images/nav-logo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import FeedIcon from "@mui/icons-material/Feed";
import ListIcon from "@mui/icons-material/List";
import EmailIcon from "@mui/icons-material/Email";
import InboxIcon from "@mui/icons-material/Inbox";
import NfcIcon from "@mui/icons-material/Nfc";
import LayersIcon from "@mui/icons-material/Layers";
import ExpandIcon from "@mui/icons-material/Expand";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Sidebar } from "./style";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const SideNav = () => {
  const [openUser, setOpenUser] = useState(false);
  const [openCampaign, setOpenCampaign] = useState(false);
  const [openDonation, setOpenDonation] = useState(false);
  const [openNft, setOpenNft] = useState(false);
  const [openMilestone, setOpenMilestone] = useState(false);
  const [openGrant, setOpenGrant] = useState(false);

  const handleClickUser = () => {
    setOpenUser(!openUser);
  };
  const handleClickCampaign = () => {
    setOpenCampaign(!openCampaign);
  };
  const handleClickDonation = () => {
    setOpenDonation(!openDonation);
  };
  const handleClickNft = () => {
    setOpenNft(!openNft);
  };
  const handleClickMilestone = () => {
    setOpenMilestone(!openMilestone);
  };
  const handleClickGrant = () => {
    setOpenGrant(!openGrant);
  };
  return (
    <Box sx={Sidebar}>
      <Toolbar sx={{ backgroundColor: "primary.main" }}>
        <img src={NavLogo} alt="Nav logo" />
      </Toolbar>
      <List>
        <Link to={"/dashboard"}>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        <ListItemButton onClick={handleClickUser}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary="Manage User" />

          {openUser ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openUser} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to="/dashboard/all-user">
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="All User" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        <Link to={"/dashboard/categories"}>
          <ListItemButton>
            <ListItemIcon>
              <ListIcon />
            </ListItemIcon>
            <ListItemText primary="Category" />
          </ListItemButton>
        </Link>

        <ListItemButton onClick={handleClickCampaign}>
          <ListItemIcon>
            <FeedIcon />
          </ListItemIcon>
          <ListItemText primary="Campaign" />

          {openCampaign ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openCampaign} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/dashboard/all-campaign"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="All Campaigns" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickNft}>
          <ListItemIcon>
            <NfcIcon />
          </ListItemIcon>
          <ListItemText primary="NFT Marketplace" />

          {openNft ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openNft} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/dashboard/mint-nft"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Mint NFT" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/minted-nft"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="View NFT(s)" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        <ListItemButton onClick={handleClickMilestone}>
          <ListItemIcon>
            <LayersIcon />
          </ListItemIcon>
          <ListItemText primary="Milestone" />

          {openMilestone ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openMilestone} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/dashboard/submitted-milestones"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Submitted Report" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/approved-milestones"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Approved Report" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/rejected-milestones"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Rejected Report" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
        <ListItemButton onClick={handleClickGrant}>
          <ListItemIcon>
            <CardMembershipIcon />
          </ListItemIcon>
          <ListItemText primary="Grants" />

          {openGrant ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openGrant} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <Link to={"/dashboard/submitted-grants"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Submitted Grants" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/approved-grants"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Approved Grants" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/completed-grants"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Completed Grants" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/rejected-grants"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Rejected Grants" />
              </ListItemButton>
            </Link>
            <Link to={"/dashboard/blocked-grants"}>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <HorizontalRuleIcon />
                </ListItemIcon>
                <ListItemText primary="Blocked Grants" />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>

        <Link to={"/dashboard/donations"}>
          <ListItemButton>
            <ListItemIcon>
              <CheckCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText primary="Donations" />
          </ListItemButton>
        </Link>

        <Link to={"/dashboard/goals"}>
          <ListItemButton>
            <ListItemIcon>
              <ExpandIcon />
            </ListItemIcon>
            <ListItemText primary="SDG Goals" />
          </ListItemButton>
        </Link>
        <Link to={"/dashboard/templates"}>
          <ListItemButton>
            <ListItemIcon>
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Email Templates" />
          </ListItemButton>
        </Link>
        <Link to={"/dashboard/send-email"}>
          <ListItemButton>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Send Email" />
          </ListItemButton>
        </Link>
      </List>
    </Box>
  );
};

export default SideNav;
