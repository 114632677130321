/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getMintedNfts,
  getTotalNfts,
  transferNftToMarketplace,
} from "../../../../blockchain";
import { useWallet } from "@txnlab/use-wallet";
import { useAppDispatch, useAppSelector } from "../../../../store";
import AdminNftCard from "./AdminNftCard";
import AddIcon from "@mui/icons-material/Add";
import { FooterBtn, style, titleStyle } from "../../../modals/styles";
import { UploadBox } from "./styles";
import Wallets from "../../../../algorandWallet";
import { client } from "../../../../algorand";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import algosdk from "algosdk";

interface nft {
  price: number;
  isAdmin: boolean;
  title: string;
  description: string;
  media: string;
  createdBy: string;
  createdAt: string;
  purchasedAt: string;
  purchasedBy: string;
  nftId: string;
}

function MintedNftContainer() {
  const { activeAccount, providers, signTransactions } = useWallet();
  const [loading, setLoading] = useState<boolean>(false);
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const [blockchainNft, setBlockchainNft] = useState<Array<any>>();
  const [blockchainTotalNft, setBlockchainTotalNft] = useState<Array<any>>();

  const [selectedNftId, setSelectedNftId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);

  const user = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();
  const [refreshData, setRefreshData] = useState(false);

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };
  const fetchAllMyNftBlockchain = async () => {
    const info = {
      app_id: parseInt(user.userAppId),
    };

    const data = await getMintedNfts(info);
    if (data.success) {
      setBlockchainNft([...data.data]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearSelection = () => {
    setSelectedNftId("");
    setOpen(false);
  };

  const handleSelect = (id: any) => {
    setSelectedNftId(id);
  };

  const handleGetTotalNft = async () => {
    dispatch(toggleAppLoading(true));
    const data = await getTotalNfts({ appId: user.userAppId });
    if (data.success) {
      setBlockchainTotalNft([...data.data]);
    }
    dispatch(toggleAppLoading(false));
    setOpen(true);
  };

  const handleContinue = async () => {
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText("sending data to blockchain"));
    const info = {
      nft_id: selectedNftId,
      // admin_wallet_address: walletClient.getDefaultAccount(),
    };
    const unsignedTxnRes = await transferNftToMarketplace(info);

    if (unsignedTxnRes.success) {
      try {
        dispatch(setAppLoadingText("signing transaction"));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedTxnRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));
        const signed = [];
        signed.push(signedTxn[0]);
        signed.push(signedTxn[1]);
        signed.push(signedTxn[2]);

        dispatch(setAppLoadingText("waiting for confirmation"));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        const toastPaylaod = {
          text: "NFT linked successfully",
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        setSelectedNftId("");
        setOpen(false);
        fetchAllMyNftBlockchain();
        return;
      } catch (err: any) {
        const toastPaylaod = {
          text: err.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
      }
    } else {
      const toastPaylaod = {
        text: unsignedTxnRes.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
  };

  useEffect(() => {
    fetchAllMyNftBlockchain();
  }, [refreshData]);

  return (
    <Box mb={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography variant="h3" align="left" mb={2}>
          Marketplace({blockchainNft?.length || 0} NFT)
        </Typography>
        <Button
          onClick={handleGetTotalNft}
          variant="contained"
          color="primary"
          className="round-button"
          sx={{
            marginLeft: "10px",
            "& .btnText": {
              display: "none",
            },
            "&:hover": {
              "& .btnText": {
                display: "block",
              },
            },
          }}
        >
          <Typography className="btnText">Add NFT</Typography>
          <AddIcon sx={{ color: "#000" }} />
        </Button>
      </Box>

      <Box>
        <Grid mt={3} mb={3} container spacing={2}>
          {blockchainNft &&
            blockchainNft.length > 0 &&
            blockchainNft.map((nft, index) => {
              return nft["frozen-state"] || nft["total"] <= 0 ? null : (
                <AdminNftCard
                  data={nft}
                  key={nft.nftId}
                  setRefreshData={setRefreshData}
                />
              );
            })}
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            height: "100%",
            outline: " 0px",
            overflow: "hidden auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "@media(max-height:700px)": {
              alignItems: "baseline",
            },
          }}
        >
          <Box
            sx={style}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Box sx={titleStyle}>
              <Typography variant="h4" align="center" mb={2}>
                Select your created NFT
              </Typography>
              <Box sx={{ textAlign: "center" }} mb={3}>
                <Typography variant="caption">Please select one NFT</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                maxHeight: "calc(100vh - 300px)",
                overflowX: "auto",
              }}
            >
              <Grid mb={3} container spacing={2}>
                {blockchainTotalNft &&
                  blockchainTotalNft.length > 0 &&
                  blockchainTotalNft.map((nft, index) => {
                    return nft["frozen-state"] || nft["total"] <= 0 ? null : (
                      <Grid item xl={3} lg={4} sm={6} xs={12}>
                        <div
                          style={
                            selectedNftId == `${nft["asset-id"]}`
                              ? {
                                  background: "#d4d4d4",
                                  padding: "16px",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                }
                              : {
                                  background: "inherit",
                                  padding: "16px",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                }
                          }
                          onClick={() => handleSelect(nft["asset-id"])}
                        >
                          <Box sx={UploadBox}>
                            <video
                              style={{ objectFit: "cover" }}
                              width="100%"
                              height="100%"
                              autoPlay
                              muted
                              loop
                              poster={nft.url}
                            >
                              <source src={nft.url} type="video/mp4" />
                              <source src={nft.url} type="video/ogg" />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                          <Typography
                            sx={{
                              color: "initial",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            variant="h6"
                            align="left"
                            mb={0}
                            className="bold"
                          >
                            {nft["unit-name"]}
                          </Typography>
                          <Typography>
                            {nft["asset-name"].length > 85
                              ? `${nft["asset-name"].slice(0, 85)}...`
                              : nft["asset-name"]}
                          </Typography>
                          <Typography>Asset Id: {nft["asset-id"]}</Typography>
                          <Typography>
                            {" "}
                            {/* <b>Price</b> - ${data.Price / 1000000} ALGO */}
                          </Typography>
                        </div>
                      </Grid>
                    );
                  })}
                {(!blockchainTotalNft || blockchainTotalNft.length === 0) && (
                  <Box
                    sx={{
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "400px",
                      border: "1px dashed",
                      margin: "20px auto 0px",
                    }}
                  >
                    <Typography>
                      Please buy some NFT's before proceeding
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
            <Box sx={FooterBtn}>
              <Button
                sx={{ color: "initial" }}
                variant="text"
                onClick={clearSelection}
              >
                Do it Later
              </Button>
              {selectedNftId && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleShowWalletOptions}
                  >
                    Continue
                  </Button>
                  {showWalletButtons && (
                    <Wallets
                      open={showWalletButtons}
                      handleClose={() => setShowWalletButtons(false)}
                      handleSubmit={handleContinue}
                    />
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default MintedNftContainer;
