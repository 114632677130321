import { useEffect, useState } from "react";
import { getDonationById } from "../../../requests/authRequest";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import algo from "../../../assets/images/algo.png";
import moment from "moment";
import { useAppDispatch } from "../../../store";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../store/slices/LoadinAndNotifSlice";

interface donation {
  campaign: any;
  currency: string;
  createdAt: string | Date;
  amount: Number | string;
  donatedBy: any;
  userId: string;
  name: string;
  donationId: string;
  transactionId: string;
}
const ViewDonation = () => {
  const { donationId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [donationInfo, setDonationInfo] = useState<donation>();
  const getDonationInfo = async () => {
    if (donationId) {
      dispatch(toggleAppLoading(true));
      const res: any = await getDonationById(donationId);
      dispatch(toggleAppLoading(false));
      if (res.success) {
        setDonationInfo(res.data.data[0]);
      } else {
        const toasterData = {
          active: true,
          text: res.data.message,
          success: false,
        };
        dispatch(setToaster(toasterData));
        navigate(-1);
      }
    } else {
      const toasterData = {
        active: true,
        text: "Trying to access info of invalid user.",
        success: false,
      };
      dispatch(setToaster(toasterData));
      navigate(-1);
    }
  };

  useEffect(() => {
    getDonationInfo();
  }, [donationId]);
  return (
    <>
      {donationInfo ? (
        <Box>
          <Typography variant="h4" align="left" mb={4} fontWeight={600}>
            View Donation Info
          </Typography>{" "}
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <Stack spacing={2}>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Campaign Title
                  </Typography>
                  <Link
                    to={`/dashboard/campaign/${donationInfo.campaign.campaignId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {donationInfo.campaign.title}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Currency
                  </Typography>
                  <Typography variant="h6" align="left">
                    {donationInfo.currency}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Goal Amount
                  </Typography>
                  <Typography variant="h6" align="left">
                    {donationInfo.campaign.goalAmount}
                  </Typography>
                </Box>

                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Campaign Creator
                  </Typography>
                  <Link
                    to={`/dashboard/user/${donationInfo.campaign.creator.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {donationInfo.campaign.creator.name}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Creator Email
                  </Typography>

                  <Typography variant="h6" align="left">
                    {donationInfo.campaign.creator.email}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Campaign Donor
                  </Typography>
                  <Link
                    to={`/dashboard/user/${donationInfo.donatedBy.userId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Typography variant="h6" align="left">
                      {donationInfo.donatedBy.name}
                    </Typography>
                  </Link>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Donated On
                  </Typography>
                  <Typography variant="h6" align="left">
                    {moment(donationInfo.createdAt).format("MMM DD YYYY")}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Donation Amount
                  </Typography>
                  <Typography variant="h6" align="left">
                    {donationInfo.amount}{" "}
                    <img
                      src={algo}
                      style={{ height: "15px", width: "15px" }}
                      alt=""
                    ></img>
                  </Typography>
                </Box>

                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Donor Email
                  </Typography>

                  <Typography variant="h6" align="left">
                    {donationInfo.donatedBy.email}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Donation Id
                  </Typography>
                  <Typography variant="h6" align="left">
                    {donationInfo.donationId}
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Typography variant="h5" fontWeight={"600"} align="left">
                    Transaction Id
                  </Typography>
                  <Typography variant="h6" align="left">
                    {donationInfo.transactionId}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
};

export default ViewDonation;
