import { Box, Button, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGridWrap, footer } from "./styles";
import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getCampaignCategories } from "../../../requests/authRequest";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import {
  setAddCategoryModal,
  setDeleteCategoryModal,
  setEditCategoryModal,
} from "../../../store/slices/ModalSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface categoryRow {
  slug: string;
  name: string;
  id: number;
  action: string;
  categoryId: string;
}

function CampaignCategories() {
  const [campaignCategory, setCampaignCategory] = useState<Array<categoryRow>>(
    []
  );
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const addCategModalState = useAppSelector(
    (state) => state.modalReducer.addCategory
  );
  const deleteCategModalState = useAppSelector(
    (state) => state.modalReducer.deleteCategory
  );
  const editCategModalState = useAppSelector(
    (state) => state.modalReducer.editCategory
  );

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      minWidth: 100,
      editable: true,
      flex: 1,
    },
    {
      field: "slug",
      headerName: "Slug",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const handleEdit = (e: any) => {
          e.stopPropagation();
          const payload = {
            editCategory: true,
            editCategoryType: "campaign",
            editCategoryName: params.row.name,
            editCategoryId: params.row.categoryId,
            editCategorySlug: params.row.slug,
          };
          dispatch(setEditCategoryModal(payload));
        };

        const handleDelete = (e: any) => {
          e.stopPropagation();
          const payload = {
            deleteCategory: true,
            deleteCategoryId: params.row.categoryId,
            deleteType: "campaign",
          };
          dispatch(setDeleteCategoryModal(payload));
        };
        return (
          <Box>
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  const fetchCampaignCategory = async () => {
    setloading(true);
    const data = {
      page: page,
      limit: 8,
    };
    const res = await getCampaignCategories(data);
    setloading(false);
    if (res.data.totalPages === page) {
      setDisableNext(true);
    } else {
      setDisableNext(false);
    }

    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    setEndPage(res.data.totalPages);
    let tempCampaign: Array<categoryRow> = [];
    if (res.success) {
      res.data.categories.map((category: any, index: number) => {
        const payload: categoryRow = {
          id: index + 1 + (res.data.currentPage - 1) * 8,
          slug: category.slug,
          name: category.title,
          action: "Action",
          categoryId: category.categoryId,
        };

        tempCampaign.push(payload);
        return null;
      });

      setCampaignCategory([...tempCampaign]);
    }
  };

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleAddCategory = () => {
    const payload = {
      addCategory: true,
      addCategoryType: "campaign",
    };
    dispatch(setAddCategoryModal(payload));
  };

  useEffect(() => {
    fetchCampaignCategory();
  }, [page]);

  useEffect(() => {
    if (!addCategModalState) {
      fetchCampaignCategory();
    }
  }, [addCategModalState]);

  useEffect(() => {
    if (!deleteCategModalState) {
      fetchCampaignCategory();
    }
  }, [deleteCategModalState]);

  useEffect(() => {
    if (!editCategModalState) {
      fetchCampaignCategory();
    }
  }, [editCategModalState]);

  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);

  return (
    <>
      <Box mb={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Typography variant="h5" align="left" mb={2}>
            Campaign Categories
          </Typography>
          <Button onClick={handleAddCategory} variant="contained">
            Add New Category
          </Button>
        </Box>
        <Box sx={DataGridWrap}>
          <DataGrid
            rows={campaignCategory}
            columns={columns}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            hideFooterPagination={true}
            hideFooter={true}
            autoHeight
          />
          <Box sx={footer}>
            <p>
              {page} - {endPage}
            </p>
            <IconButton onClick={handleBack} disabled={disableBack}>
              <ArrowBackIosNewIcon />
            </IconButton>
            <IconButton onClick={handleNext} disabled={disableNext}>
              <ArrowForwardIosIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default CampaignCategories;
