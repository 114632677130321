import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AdminWrapper from "./components/adminWrapper/AdminWrapper";
import { AllCampaign } from "./components/dashboard/campaigns/allCampaigns";
import Categories from "./components/dashboard/categories";
import DashboardHome from "./components/dashboard/dashboardHome";
import AllGoals from "./components/dashboard/goals/goalsPage";
import AllUser from "./components/dashboard/manageUser/allUser";
import { ApprovedMilestones } from "./components/dashboard/milestones/approvedMiletones";
import { RejectedMilestones } from "./components/dashboard/milestones/rejectedMilestones";
import { SubmitttedMilestones } from "./components/dashboard/milestones/submittedMilestones";
import { ViewMilestone } from "./components/dashboard/milestones/viewMilestone";
import { MintedNftContainer } from "./components/dashboard/nft/mintedNft";
import { MintNft } from "./components/dashboard/nft/mintNft";
import { ViewNft } from "./components/dashboard/nft/viewNft";
import { ViewCampaign } from "./components/dashboard/viewCampaign";
import { ViewUser } from "./components/dashboard/viewUser";
import LoadingScreen from "./loaders/LoadingScreen";
import { useAppSelector } from "./store";
import Login from "./views/auth/login/Login";
import Dashboard from "./views/dashboard/Dashboard";
import { ApprovedGrants } from "./components/dashboard/grants/approvedGrants";
import { RejectedGrants } from "./components/dashboard/grants/rejectedGrants";
import { SubmitttedGrants } from "./components/dashboard/grants/submittedGrants";
import { BlockedGrants } from "./components/dashboard/grants/blockedGrants";
import { CompletedGrants } from "./components/dashboard/grants/completedGrants";
import { ViewGrant } from "./components/dashboard/grants/viewGrant";
import Templates from "./components/emailTemplates/templates";
import EditTemplates from "./components/emailTemplates/editTemplate";
import SendEmail from "./components/sendEmail";
import { Donations } from "./components/dashboard/donations";
import ViewDonation from "./components/dashboard/donations/ViewDonation";

function RouteConfig() {
  const loggedIn = useAppSelector((state) => state.userReducer.loggedIn);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Router>
        <Routes>
          <Route path="/" element={<AdminWrapper />}>
            <Route
              index
              element={
                loggedIn ? (
                  <Navigate to={"dashboard"} replace />
                ) : (
                  <Navigate to={"login"} replace />
                )
              }
            />
            <Route path="dashboard" element={<Dashboard />}>
              <Route index element={<DashboardHome />} />
              <Route path="all-user" element={<AllUser />} />
              <Route path="categories" element={<Categories />} />
              <Route path="all-campaign" element={<AllCampaign />} />
              <Route path="user/:userId" element={<ViewUser />} />
              <Route path="campaign/:campaignId" element={<ViewCampaign />} />
              <Route path="donation/:donationId" element={<ViewDonation />} />
              <Route path="view-nft/:nftId" element={<ViewNft />} />
              <Route path="mint-nft" element={<MintNft />} />
              <Route path="minted-nft" element={<MintedNftContainer />} />
              <Route path="goals" element={<AllGoals />} />
              <Route path="templates" element={<Templates />} />

              <Route path="send-email" element={<SendEmail />} />
              <Route path="edit-templates/:id" element={<EditTemplates />} />
              <Route
                path="submitted-milestones"
                element={<SubmitttedMilestones />}
              />
              <Route
                path="approved-milestones"
                element={<ApprovedMilestones />}
              />
              <Route
                path="rejected-milestones"
                element={<RejectedMilestones />}
              />
              <Route
                path="milestone/:milestoneId"
                element={<ViewMilestone />}
              />
              <Route path="submitted-grants" element={<SubmitttedGrants />} />
              <Route path="approved-grants" element={<ApprovedGrants />} />
              <Route path="donations" element={<Donations />} />
              <Route path="completed-grants" element={<CompletedGrants />} />
              <Route path="rejected-grants" element={<RejectedGrants />} />
              <Route path="blocked-grants" element={<BlockedGrants />} />
              <Route path="grant/:grantId" element={<ViewGrant />} />
            </Route>
            <Route path="login" element={<Login />} />
          </Route>
          <Route path="*" element={<p>You have hit a 404 not found</p>} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default RouteConfig;
