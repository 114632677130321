var axios = require("axios");

const URL = process.env.REACT_APP_URL;

export const handleEmailLogin = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/api/auth/login/admin`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: res.data,
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};
