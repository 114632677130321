const URL = process.env.REACT_APP_BLOCKCHAIN_URL;
var axios = require("axios");

export const approveCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/reject_approve_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error creting account" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const createNftAdmin = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/create_asset`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error creting NFT" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getMintedNfts = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/nft_marketplace`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error fetching nft" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveMilestoneBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/approve_milestone`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error fetching nft" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const rejectMilestoneBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/reject_milestone`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error fetching nft" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const rejectCampaignBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/block_campaign`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error in block campaign" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const handleIPFSUpload = async (fileData) => {
  let data = new FormData();
  data.append("file", fileData.file);
  data.append("userAppId", fileData.userAppId);

  try {
    const res = await axios.post(`${URL}/ipfs`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };
      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error logging out data" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const getTotalNfts = async (info) => {
  var config = {
    method: "get",
    url: `${URL}/total_nft/${info.appId}`,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error fetching nft" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const transferNftToMarketplace = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/nft_marketplace/transfer_nft_to_marketplace`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error creting NFT" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const withdrawNft = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/nft_marketplace/withdraw_nft`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error in remove NFT" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};

export const approveGrantBlockchain = async (info) => {
  const data = JSON.stringify(info);

  var config = {
    method: "post",
    url: `${URL}/grant_creator/grant/admin_review`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  try {
    const res = await axios(config);
    if (res.status === 200) {
      let returnRes = {
        success: true,
        data: res.data,
      };

      return returnRes;
    } else {
      let returnRes = {
        success: false,
        data: { message: "Error creting account" },
      };
      return returnRes;
    }
  } catch (err) {
    let returnRes = {
      success: false,
      data: err.response
        ? err.response.data
        : { message: "Error: Network Error" },
    };
    return returnRes;
  }
};
