import { useEffect, useState } from "react";
import { getDonations } from "../../../requests/authRequest";
import { Box, IconButton, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { DataGridWrap, footer, SearchRow } from "./style";
import CustomNoRowsOverlay from "../../table/CustomNoRowOverlay";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { toggleAppLoading } from "../../../store/slices/LoadinAndNotifSlice";
import moment from "moment";
import { useAppDispatch } from "../../../store";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";

interface donationRow {
  id: number;
  currency: string;
  campaign: string;
  donatedOn: string | Date;
  amount: string;
  donatedBy: string;
  transactionId: string;
  action: any;
}
const Donations = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [donations, setDonations] = useState<Array<donationRow>>([]);
  const [page, setPage] = useState<number>(1);
  const [endPage, setEndPage] = useState<number>();
  const [loading, setloading] = useState<boolean>(false);
  const [disableNext, setDisableNext] = useState<boolean>(false);
  const [disableBack, setDisableBack] = useState<boolean>(false);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "campaignTitle",
      headerName: "Campaign",
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => {
        if (params.row.campaign && params.row.campaign.title) {
          return params.row.campaign.title;
        } else {
          return "";
        }
      },
    },

    {
      field: "currency",
      headerName: "Currency",
      minWidth: 90,
      flex: 1,
      editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth: 90,
      flex: 1,
      editable: true,
    },
    {
      field: "createdat",
      headerName: "Donated On",
      minWidth: 100,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        moment(params.row.createdAt).format("DD-MMM-YYYY"),
    },
    {
      field: "donatedBy",
      headerName: "Donated By",
      sortable: false,
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridValueGetterParams) => params.row.donatedBy.name,
    },
    {
      field: "transactionId",
      headerName: "Transaction ID",
      minWidth: 460,
      editable: true,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      minWidth: 90,
      flex: 1,
      renderCell: (params) => {
        const handleView = (e: any) => {
          e.stopPropagation();
          navigate(`/dashboard/donation/${params.row.donationId}`);
        };
        return (
          <Box>
            <IconButton onClick={handleView}>
              <VisibilityIcon />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setloading(true);
        const data = {
          page: page,
          limit: 8,
        };
        const res = await getDonations(data);
        setloading(false);

        if (res.data.totalPages === page) {
          setDisableNext(true);
        } else {
          setDisableNext(false);
        }

        if (page === 1) {
          setDisableBack(true);
        } else {
          setDisableBack(false);
        }

        if (page === 1 && endPage === 1) {
          setDisableNext(true);
          setDisableBack(true);
        }

        if (res.data.totalPages > page) {
          setDisableNext(false);
        }
        setEndPage(res.data.totalPages);

        if (res && res.success) {
          const updatedDonations = res.data.data.map(
            (item: any, index: any) => {
              return {
                id: index + 1 + (page - 1) * 8,
                ...item,
              };
            }
          );
          setDonations(updatedDonations);
        } else {
          setDonations([]);
        }
      } catch (error: any) {
        let returnRes = {
          success: false,
          data: error.res
            ? error.res.data
            : { message: "Error: Network Error" },
        };
        return returnRes;
      }
    };

    fetchData();
  }, [page]);

  const handleNext = () => {
    if (endPage && page < endPage) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page === 1) {
      setDisableBack(true);
    } else {
      setDisableBack(false);
    }

    if (page === 1 && endPage === 1) {
      setDisableNext(true);
      setDisableBack(true);
    }
    if (endPage && endPage > page) {
      setDisableNext(false);
    }
  }, [endPage]);
  useEffect(() => {
    if (loading) {
      dispatch(toggleAppLoading(true));
    } else {
      dispatch(toggleAppLoading(false));
    }
  }, [loading]);
  return (
    <>
      <Typography variant="h5" align="left" mb={2}>
        Donations
      </Typography>

      <Box sx={DataGridWrap}>
        <DataGrid
          rows={donations}
          columns={columns}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          hideFooterPagination={true}
          hideFooter={true}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          getRowId={(row) => row.id}
        />
        <Box sx={footer}>
          <p>
            {page} - {endPage}
          </p>
          <IconButton onClick={handleBack} disabled={disableBack}>
            <ArrowBackIosNewIcon />
          </IconButton>
          <IconButton onClick={handleNext} disabled={disableNext}>
            <ArrowForwardIosIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
export default Donations;
