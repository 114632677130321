import { Box, Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useWallet } from "@txnlab/use-wallet";
import algosdk from "algosdk";
import React from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../../../../algorand";
import { withdrawNft } from "../../../../blockchain";
import { useAppDispatch } from "../../../../store";
import { useState } from "react";
import Wallets from "../../../../algorandWallet";
import {
  setAppLoadingText,
  setToaster,
  toggleAppLoading,
} from "../../../../store/slices/LoadinAndNotifSlice";
import { UploadBox } from "./styles";

interface adminNftCard {
  data: any;
  setRefreshData: React.Dispatch<React.SetStateAction<boolean>>;
}

function AdminNftCard({ data, setRefreshData }: adminNftCard) {
  const { activeAccount, providers, signTransactions } = useWallet();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showWalletButtons, setShowWalletButtons] = useState<boolean>(false);

  const handleShowWalletOptions = async (
    e?: React.MouseEvent<HTMLButtonElement>
  ) => {
    e?.preventDefault();

    setShowWalletButtons(true);
  };

  const handleSelect = () => {
    if (data.nftId) {
      navigate(`/dashboard/view-nft/${data.nftId}`);
    }
  };

  const handleRemove = async (id: any) => {
    dispatch(toggleAppLoading(true));
    dispatch(setAppLoadingText("sending data to blockchain"));
    const info = {
      nft_id: id,
    };
    const unsignedTxnRes = await withdrawNft(info);

    if (unsignedTxnRes.success) {
      try {
        dispatch(setAppLoadingText("signing transaction"));
        dispatch(toggleAppLoading(false));
        const transactionArr = unsignedTxnRes.data.map((data: any) => {
          return {
            txn: algosdk.decodeUnsignedTransaction(
              Buffer.from(data.txn, "base64")
            ),
          };
        });
        const currentProvider = providers?.find(
          (wallet: any) => wallet.isActive
        );
        await currentProvider?.reconnect();
        const encodedTxns = transactionArr.map((txn: any) => {
          return algosdk.encodeUnsignedTransaction(txn.txn);
        });
        const signedTxn = await signTransactions(encodedTxns);
        dispatch(toggleAppLoading(true));

        const signed = [];
        signed.push(signedTxn[0]);
        dispatch(setAppLoadingText("waiting for confirmation"));
        const transationRes = await client
          .sendRawTransaction(
            signedTxn.map((sign: any) => Buffer.from(sign, "base64"))
          )
          .do();

        let confirmedTxn = await algosdk.waitForConfirmation(
          client,
          transationRes.txId,
          4
        );
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
        const toastPaylaod = {
          text: "NFT removed successfully",
          success: true,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        setRefreshData(true);
        return;
      } catch (err: any) {
        const toastPaylaod = {
          text: err.message,
          success: false,
          active: true,
        };
        dispatch(setToaster(toastPaylaod));
        dispatch(setAppLoadingText(""));
        dispatch(toggleAppLoading(false));
      }
    } else {
      const toastPaylaod = {
        text: unsignedTxnRes.data.message,
        success: false,
        active: true,
      };
      dispatch(setToaster(toastPaylaod));
      dispatch(toggleAppLoading(false));
    }
  };

  return (
    <Grid item xl={3} lg={4} sm={6} xs={12}>
      <div
        style={{
          background: "#d4d4d4",
          padding: "16px",
          borderRadius: "10px",
          cursor: "pointer",
          textAlign: "left",
          minHeight: "350px",
          height: "350px",
        }}
        onClick={handleSelect}
      >
        <Box sx={UploadBox}>
          <video
            style={{ objectFit: "cover" }}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
            poster={data.url}
            controls={false}
          >
            <source src={data.url} type="video/mp4" />
            <source src={data.url} type="video/ogg" />
            Your browser does not support the video tag.
          </video>
        </Box>
        <Typography
          sx={{
            color: "initial",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
          variant="h6"
          align="left"
          mb={2}
          className="bold"
          fontWeight={600}
        >
          {data["unit-name"]}
        </Typography>
        <Typography height={"84px"} mb={2}>
          {data["asset-name"].length > 85
            ? `${data["asset-name"].slice(0, 85)}...`
            : data["asset-name"]}
        </Typography>
        <Stack
          direction="row"
          spacing={20}
          style={{
            marginTop: "0",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <>
            <Button
              onClick={handleShowWalletOptions}
              variant="contained"
              style={{
                height: "30px",
                backgroundColor: "#FF4033",
                fontSize: "10px",
                padding: "10px 20px",
                margin: "0px 2px",
              }}
            >
              Remove
            </Button>
            {showWalletButtons && (
              <Wallets
                open={showWalletButtons}
                handleClose={() => setShowWalletButtons(false)}
                handleSubmit={() => handleRemove(data["asset-id"])}
              />
            )}
          </>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: "#000000e3",
                  fontSize: "16px",
                  padding: "18px",
                  maxWidth: "320px",
                  maxHeight: "420px",
                  textAlign: "justify",
                  whiteSpace: "pre-line",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  "& .MuiTooltip-arrow": {
                    color: "#ffffff",
                  },
                },
              },
            }}
            title={
              "Asset Id: " +
              data["asset-id"] +
              "\nUnit Name: " +
              data["unit-name"] +
              "\nAsset Name: " +
              data["asset-name"] +
              "\nURL: " +
              data["url"]
            }
            placement="top-start"
          >
            <Button
              variant="contained"
              style={{
                height: "30px",
                backgroundColor: "#61f359",
                fontSize: "10px",
                padding: "10px 20px",
                margin: "0px 2px",
              }}
            >
              Quick Info
            </Button>
          </Tooltip>
        </Stack>
      </div>
    </Grid>
  );
}

export default AdminNftCard;
